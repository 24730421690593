import React from 'react'
import queryString from 'query-string'
import { useLocation } from "react-router-dom";
export const Preview: React.FC = () => {
    const location = useLocation()
    const { search } = location
    const params: any = queryString.parse(search);
    const src: string = decodeURIComponent(params.src);
    const width = window.innerWidth;
    const height = window.innerHeight;
    // eslint-disable-next-line
    return <iframe src={src} style={{ width: width , height: height , borderWidth: '0' }}></iframe>
}