import { Card, List, Image, Grid, Footer, Empty, Toast, Ellipsis } from 'antd-mobile';
import { BellOutline, RightOutline, TeamOutline } from 'antd-mobile-icons';
import React, { useEffect, useState } from 'react';
import queryString from 'query-string'
import './index.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { axiosPost } from '../../Utils/request';
export const ClubIndex: React.FC = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { search } = location
    const params = queryString.parse(search);
    const [data, setData] = useState(null);
    const [news, setNews] = useState([]);
    const getClubData = () => {
        Toast.show({ icon: 'loading', content: '加载中…', duration: 0, maskClickable: false });
        const para = {
            relkey: params.relkey,
            keyword: '',
            index: 0,
            size: 10000
        };
        axiosPost(0, para, 'Club', (res: any) => {
            if (res.code === 0) {
                setData(res.data);
                getNews();
                return;
            }
            getNews();
            setData(null);
            return;
        })
    }
    const getNews = () => {
        const para = {
            relkey: params.relkey,
            key: '',
            index: 0,
            size: 10000
        };
        axiosPost(5, para, 'Club', (res: any) => {
            Toast.clear();
            if (res.code === 0) {
                setNews(res.data.list);
                return;
            }
            setNews([]);
            return;
        })
    }
    const onItemClick = (item, registed) => {
        navigate('/club/detail?relkey=' + params.relkey + '&type=' + item.typeId + '&key=' + item.key + '&registed=' + registed);
    }
    const onClubRegistClick = () => {
        navigate('/club/regist?relkey=' + params.relkey + '&type=01&key=');
    }
    const onActivityRegistClick = () => {
        navigate('/club/regist?relkey=' + params.relkey + '&type=02&key=');
    }
    // eslint-disable-next-line
    useEffect(() => { document.title = '我的社团'; getClubData(); }, []);
    return <div style={{ padding: 10, display: 'block', height: 'calc(100vh - 0px)', backgroundColor: '#f8f8f8', overflowY: 'auto' }}>
        {
            data !== null
                ? <>
                    <Grid columns={2} gap={10}>
                        <Grid.Item>
                            <Card onClick={() => { onClubRegistClick() }} style={{ border: '1px solid #e8e8e8' }} title={<div style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '18px' }}><TeamOutline fontSize={20} style={{ marginRight: '10px', color: '#000' }} />选社团课</div>} extra={<RightOutline />}></Card>
                        </Grid.Item>
                        <Grid.Item>
                            <Card onClick={() => { onActivityRegistClick() }} style={{ border: '1px solid #e8e8e8' }} title={<div style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '18px' }}><BellOutline fontSize={20} style={{ marginRight: '10px', color: '#000' }} />活动报名</div>} extra={<RightOutline />}></Card>
                        </Grid.Item>
                    </Grid>
                    {
                        data.registed.length > 0
                            ? <List header={<div style={{ color: '#000', fontWeight: 'bold' }}>我的报名</div>} style={{ border: '1px solid #e8e8e8', marginTop: 10, borderRadius: 8, backgroundColor: '#fff', paddingBottom: 10, '--border-bottom': 'none' }} className='dataList' >
                                {
                                    data.registed.map(item => <List.Item
                                        prefix={
                                            <Image
                                                src={item.icon}
                                                style={{ borderRadius: 5 }}
                                                fit='cover'
                                                width={60}
                                                height={60}
                                            />
                                        }
                                        description={item.desc}
                                        onClick={() => { onItemClick(item, true) }}
                                        clickable
                                        key={item.key}
                                    >
                                        {item.title}
                                    </List.Item>)
                                }
                            </List>
                            : null
                    }
                    {
                        news !== null && news.length > 0
                            ? <List header={<div style={{ color: '#000', fontWeight: 'bold' }}>最新动态</div>} className='dataList' style={{ border: '1px solid #e8e8e8', marginTop: 10, borderRadius: 8, backgroundColor: '#fff', paddingBottom: 10, '--border-bottom': 'none' }}>
                                {
                                    news.map((item, index) => index < 5 ? <List.Item key={item.key} onClick={() => { navigate('/club/article?relkey=' + params.relkey + '&html=' + item.html + '&key=' + item.key); }}>
                                        <div style={{ float: 'left', width: '80px', textAlign: 'left', color: '#333' }}><Ellipsis style={{ color: '#333' }} direction='end' content={item.mainTitle && item.mainTitle !== null ? item.mainTitle : ''} /></div>
                                        <div><Ellipsis style={{ color: '#666' }} direction='end' content={item.title} /></div>
                                    </List.Item> : null)
                                }
                            </List>
                            : null
                    }
                    <List header={<div style={{ color: '#000', fontWeight: 'bold' }}>社团与活动</div>} className='dataList' style={{ border: '1px solid #e8e8e8', marginTop: 10, borderRadius: 8, backgroundColor: '#fff', paddingBottom: 10, '--border-bottom': 'none' }}>
                        {
                            data.clubs.length > 0
                                ? data.clubs.map(item => <List.Item
                                    prefix={
                                        <Image
                                            src={item.icon}
                                            style={{ borderRadius: 5 }}
                                            fit='cover'
                                            width={60}
                                            height={60}
                                        />
                                    }
                                    description={item.desc}
                                    onClick={() => { onItemClick(item, false) }}
                                    clickable
                                    key={item.key}
                                >
                                    {item.title}
                                </List.Item>)
                                : <List.Item style={{ textAlign: 'center' }}>
                                    <Empty description='暂无数据' />
                                </List.Item>
                        }
                    </List>
                </>
                : <Empty description='暂未开放' />
        }

        <Footer style={{ padding: '10px', backgroundColor: '#f8f8f8' }} content={<>&copy;{new Date().getFullYear()}贵州金宏鑫科技有限公司</>}></Footer>
    </div>
}