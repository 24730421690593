import React, { RefObject, useEffect, useState } from 'react';
import queryString from 'query-string'
import './index.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Form, Input, Picker, Toast } from 'antd-mobile';
import type { PickerRef } from 'antd-mobile/es/components/picker';
import { axiosPost } from '../../Utils/request';
export const ClubRegist: React.FC = () => {
    const [form] = Form.useForm()
    const location = useLocation()
    const navigate = useNavigate()
    const { search } = location
    const params = queryString.parse(search);
    const [data, setData] = useState(null);
    const getClubData = () => {
        Toast.show({ icon: 'loading', content: '加载中…', duration: 0, maskClickable: false });
        const para = {
            relkey: params.relkey,
            keyword: '',
            index: 0,
            size: 10000
        };
        axiosPost(0, para, 'Club', (res: any) => {
            if (res.code === 0) {
                setData(res.data);
                getCurrent();
                return;
            }
            getCurrent();
            setData(null);
            return;
        })
    }
    const getCurrent = () => {
        let body: any = {
            key: '',
            relkey: params.relkey,
            type: '1'
        }
        axiosPost(13, body, 'Login', (res: any) => {
            if (res.code === 0) {
                getStudent(res.data.userRels[0].EnterpriseNO, res.data.userRels[0].TeaKey);
                return;
            }
            body.type = '2';
            axiosPost(13, body, 'Login', (res: any) => {
                if (res.code === 0) {
                    getStudent(res.data.userRels[0].EnterpriseNO, res.data.userRels[0].TeaKey);
                    return;
                }
                Toast.clear();
                return;
            })
        })
    }
    const getStudent = (no, key) => {
        let body: any = {
            key: key,
            enterprise: no
        }
        axiosPost(6, body, 'Student', (res: any) => {
            if (res.code === 0) {
                Toast.clear();
                form.setFieldsValue({ id: res.data[0].A01007, org: res.data[0].A01001Text });
                return;
            }
            form.setFieldsValue({ id: null, org: null });
            Toast.clear();
            return;
        })
    }
    const onFormFinish = (values: any) => {
        Toast.show({ icon: 'loading', content: '报名中…', duration: 0, maskClickable: false });
        const body = {
            relkey: params.relkey,
            key: values.key[0],
            type: params.type,
            id: values.id,
            org: values.org
        };
        axiosPost(2, body, 'Club', (res: any) => {
            if (res.code === 0) {
                Toast.show({ icon: 'success', content: '成功', maskClickable: false });
                navigate('/club/index?relkey=' + params.relkey);
                return;
            }
            Toast.show({ icon: 'fail', content: res.message, maskClickable: false });
            return;
        })
    }
    // eslint-disable-next-line
    useEffect(() => { document.title = '社团报名'; getClubData(); }, [])
    return <div style={{ backgroundColor: '#f8f8f8' }}>
        <div style={{ padding: 0, height: 'calc(100vh - 0px)', overflowY: 'auto' }}>
            {
                data !== null
                    ? <Form
                        layout='horizontal'
                        mode='card'
                        name='form'
                        form={form}
                        initialValues={params.key && data !== null ? { key: [params.key] } : {}}
                        onFinish={onFormFinish}
                        style={{ marginTop: 10 }}
                        footer={
                            <Button block type='submit' color='primary'>
                                提交
                            </Button>
                        }>
                        <Form.Item
                            name='org'
                            label='年级班别'
                            rules={[
                                {
                                    required: true,
                                    message: '年级班别必填',
                                }
                            ]}
                        >
                            <Input placeholder='请输入年级班别' />
                        </Form.Item>
                        <Form.Item
                            name='id'
                            label='身份证号'
                            rules={[
                                {
                                    required: true,
                                    message: '身份证号码必填',
                                },
                                {
                                    pattern: /^(\d{15}$|^\d{18}$|^\d{17}(\d|X|x))$/,
                                    message: '身份证号码格式不正确',
                                },
                            ]}
                        >
                            <Input placeholder='请输入身份证号码' />
                        </Form.Item>
                        <Form.Item
                            name='key'
                            label={params.type === '01' ? '社团课' : '活动'}
                            trigger='onConfirm'
                            rules={[
                                {
                                    required: true,
                                    message: params.type === '01' ? '社团课必选' : '活动必选',
                                }
                            ]}
                            onClick={(e, picpkerRef: RefObject<PickerRef>) => {
                                picpkerRef.current?.open()
                            }}
                        >
                            <Picker columns={[data.clubs.filter(f => f.typeId === params.type)]}>
                                {
                                    (value: any) => {
                                        if (value && value.length > 0) {
                                            return value[0].label;
                                        }
                                        return <span style={{ color: '#bbb' }}>请选择</span>;
                                    }
                                }
                            </Picker>
                        </Form.Item>
                    </Form>
                    : <></>
            }
        </div>
    </div>
}