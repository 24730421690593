
import React, { useEffect, useState } from 'react'
import { Toast, List, Input, Button } from 'antd-mobile'
import wx from 'weixin-js-sdk';
import { useNavigate, useLocation } from "react-router-dom";
import { axiosPost } from '../../Utils/request'
import { SearchPicker } from '../../Components/SearchPicker';
import { isWxDebug, stringIsNullOrEmpty } from '../../Utils/utils';
import queryString from 'query-string'

export const Validate: React.FC = () => {
    const [enterpriseList, setEnterpriseList] = useState([]);
    const [enterprise, setEnterprise] = useState(null);
    const [orgList, setOrgList] = useState([]);
    const [org, setOrg] = useState(null);
    const [idcard, setIdCard] = useState(null);
    const [name, setName] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { search } = location
    const params = queryString.parse(search);
    const initJssdk = () => {
        if (!isWxDebug) {
            let url = window.location.href;
            var isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if (isIOS) {
                url = 'http://iss.jhxhzn.com/wechat/index?openId=' + params.openId + '&unionId=' + params.unionId + '&t=' + params.t;
            }
            const body = {
                url: url
            };
            Toast.show({ icon: 'loading', content: '加载中…', duration: 0, maskClickable: false });
            axiosPost(1, body, 'Weixin', (res: any) => {
                if (res.code === 0) {
                    initJssdkConfig(res.data);
                    return;
                }
                Toast.clear();
                Toast.show({ icon: 'fail', content: res.message, maskClickable: false });
                return;
            });
            return;
        }
        getEnterprise();
        return;
    }
    const initJssdkConfig = (options: any) => {
        try {
            options.debug = false;
            options.jsApiList = ['updateAppMessageShareData', 'hideMenuItems'];
            wx.config(options);
            wx.error((res: any) => {
                Toast.clear();
            });
            wx.ready(() => {
                wx.hideMenuItems({
                    menuList: ['menuItem:share:timeline', 'menuItem:share:qq', 'menuItem:share:weiboApp', 'menuItem:favorite', 'menuItem:share:facebook', 'menuItem:share:QZone', 'menuItem:editTag', 'menuItem:delete', 'menuItem:copyUrl', 'menuItem:originPage', 'menuItem:readMode', 'menuItem:openWithQQBrowser', 'menuItem:openWithSafari', 'menuItem:share:email', 'menuItem:share:brand'],
                    fail: (res: any) => {
                        Toast.clear();
                        Toast.show({ icon: 'fail', content: '2|' + JSON.stringify(res), maskClickable: false });
                    },
                    success: () => {
                        wx.updateAppMessageShareData({
                            title: '在校缴费',
                            desc: '点击链接可帮助学生进行在线缴费',
                            link: 'http://api.jhxhzn.com/jumpPay.html',
                            imgUrl: 'http://api.jhxhzn.com/web/hyx.png',
                            success: () => {
                                getEnterprise();
                            },
                            fail: (res: any) => {
                                Toast.clear();
                                Toast.show({ icon: 'fail', content: '3|' + JSON.stringify(res), maskClickable: false });
                            }
                        });
                    }
                });
            });
        } catch (e) {
            Toast.clear();
            Toast.show({ icon: 'fail', content: e, maskClickable: false });
        }
    }
    const getEnterprise = () => {
        const body = {
            where: 'QRCodeFee=1'
        };
        axiosPost(48, body, 'Enterprise', (res: any) => {
            Toast.clear();
            if (res.code === 0) {
                let newList = [];
                res.data.list.forEach((item: any) => {
                    item.label = item.EnterpriseName;
                    item.value = item.EnterpriseNO;
                    newList.push(item);
                });
                setEnterpriseList(newList);
                onEnterpriseChange(params.enterprise);
                return;
            }
            Toast.show({ icon: 'fail', content: res.message, maskClickable: false });
            return;
        })
    }
    const onEnterpriseChange = (e: any) => {
        setEnterprise(e);
        getOrg(e);
    }
    const getOrg = (enp: String) => {
        Toast.show({ icon: 'loading', content: '加载中…', duration: 0, maskClickable: false });
        const body = {
            enterprise: enp,
            model: '',
            flag: 'HYBM'
        };
        axiosPost(37, body, 'Data', (res: any) => {
            Toast.clear();
            if (res.code === 0) {
                let newList = [];
                res.data.list.forEach((item: any) => {
                    item.children.forEach((child: any) => {
                        if (child.children.length > 0) {
                            child.children.forEach((child1: any) => {
                                child1.label = child1.CodeAllName;
                                child1.value = child1.CodeALLID;
                                newList.push(child1);
                            })
                        }
                        else {
                            child.label = child.CodeAllName;
                            child.value = child.CodeALLID;
                            newList.push(child);
                        }
                    });
                });
                setOrgList(newList);
                return;
            }
            Toast.show({ icon: 'fail', content: res.message, maskClickable: false });
            return;
        })
    }
    const onNameChange = (e: any) => {
        setName(e);
    }
    const onIdChange = (e: any) => {
        setIdCard(e)
    }
    const onOrgChange = (e: any) => {
        setOrg(e);
    }
    const onValidate = () => {
        if (stringIsNullOrEmpty(enterprise)) {
            Toast.clear();
            Toast.show({ icon: 'fail', content: '请选择学校', maskClickable: false });
            return;
        }
        if (stringIsNullOrEmpty(org)) {
            Toast.clear();
            Toast.show({ icon: 'fail', content: '请选择年级班别', maskClickable: false });
            return;
        }
        if (stringIsNullOrEmpty(idcard)) {
            Toast.clear();
            Toast.show({ icon: 'fail', content: '请填写身份证号', maskClickable: false });
            return;
        }
        if (stringIsNullOrEmpty(name)) {
            Toast.clear();
            Toast.show({ icon: 'fail', content: '请填写学生姓名', maskClickable: false });
            return;
        }
        Toast.show({ icon: 'loading', content: '认证中…', duration: 0, maskClickable: false });
        const body = {
            enterprise: enterprise,
            org: org,
            id: idcard,
            name: name
        };
        axiosPost(8, body, 'Order', (res: any) => {
            Toast.clear();
            if (res.code === 0) {
                navigate('/wechat/pay?openId=' + params.openId + '&unionId=' + params.unionId + '&t=' + params.t + '&student=' + res.data.list[0].key + '&enterprise=' + enterprise + '&ename=' + enterpriseList.filter((f: any) => f.value === enterprise)[0].label + '&name=' + res.data.list[0].name + '&orgName=' + res.data.list[0].orgName);
                return;
            }
            Toast.show({ icon: 'fail', content: res.message, maskClickable: false });
            return;
        })
    }
    // eslint-disable-next-line
    useEffect(() => { document.title = '在线缴费'; initJssdk(); }, [])
    return <div>
        <List header={'身份验证'}>
            {enterpriseList.length > 0 ? <SearchPicker pickerData={enterpriseList} value={enterprise} disabled={false} title={'学校'} onChange={onEnterpriseChange} /> : <List.Item extra={'未获取到学校'} clickable>学校</List.Item>}
            {orgList.length > 0 ? <SearchPicker pickerData={orgList} value={org} disabled={false} title={'年级班别'} onChange={onOrgChange} /> : <List.Item extra={'请先选择学校'} clickable>年级班别</List.Item>}
            <List.Item clickable extra={<Input value={idcard} style={{ '--text-align': 'right' }} onChange={onIdChange} placeholder={'请输入证件号码'} />}>
                证件号码
            </List.Item>
            <List.Item clickable extra={<Input value={name} style={{ '--text-align': 'right' }} onChange={onNameChange} placeholder={'请输入学生姓名'} />}>
                学生姓名
            </List.Item>
            <List.Item>
                <Button block color='primary' size='middle' onClick={onValidate}>
                    下一步
                </Button>
            </List.Item>
        </List>
    </div>

}