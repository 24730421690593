import { Button, Ellipsis, List, Empty, Toast } from 'antd-mobile';
import React, { useEffect, useState } from 'react';
import queryString from 'query-string'
import './index.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { axiosPost } from '../../Utils/request';
export const ClubDetail: React.FC = () => {
    const location = useLocation()
    const { search } = location
    const navigate = useNavigate()
    const params = queryString.parse(search);
    const [data, setData] = useState(null);
    const [news, setNews] = useState([]);
    const getClubData = () => {
        Toast.show({ icon: 'loading', content: '加载中…', duration: 0, maskClickable: false });
        const para = {
            relkey: params.relkey,
            keyword: '',
            index: 0,
            size: 10000
        };
        axiosPost(0, para, 'Club', (res: any) => {
            if (res.code === 0) {
                let item = params.registed === 'true' ? res.data.registed.filter(f => f.key === params.key)[0] : res.data.clubs.filter(f => f.key === params.key)[0];
                setData(item);
                getNews(item);
                return;
            }
            Toast.clear();
            setData(null);
            return;
        })

    }
    const getNews = (item) => {
        const para = {
            relkey: params.relkey,
            key: item.key,
            index: 0,
            size: 5
        };
        axiosPost(5, para, 'Club', (res: any) => {
            Toast.clear();
            if (res.code === 0) {
                setNews(res.data.list);
                return;
            }
            setNews([]);
            return;
        })
    }
    const onRegistClick = () => {
        navigate('/club/regist?relkey=' + params.relkey + '&type=' + data.typeId + '&key=' + data.key);
    }
    const onDetailClick = () => {
        navigate('/club/article?relkey=' + params.relkey + '&html=' + data.html + '&key=' + data.key);
    }
    // eslint-disable-next-line
    useEffect(() => { document.title = '社团详情'; getClubData(); }, [])
    return <div style={{ backgroundColor: '#f8f8f8', height: 'calc(100vh - 0px)' }}>
        {
            data !== null
                ? <div style={{ padding: 10, overflowY: 'hidden', height: 'calc(100vh - 0px)' }}>
                    <List style={{ border: '1px solid #e8e8e8', backgroundColor: '#fff', marginTop: '0px', borderRadius: 8, paddingBottom: 10, '--border-bottom': 'none' }} header={<div style={{ color: '#000', fontWeight: 'bold' }}>基本信息</div>}>
                        <List.Item extra={data.title}>
                            名称
                        </List.Item>
                        <List.Item extra={data.teacher}>
                            教师
                        </List.Item>
                        <List.Item extra={data.typeId === '01' ? '社团课' : '学校活动'}>
                            类型
                        </List.Item>
                        <List.Item clickable onClick={() => { onDetailClick() }}>
                            <span style={{ color: '#1677ff' }}>详细介绍</span>
                        </List.Item>
                    </List>
                    <List style={{ border: '1px solid #e8e8e8', height: 'calc(100vh - 340px)', backgroundColor: '#fff', marginTop: '10px', borderRadius: 8, paddingBottom: 10, '--border-bottom': 'none' }} header={<div style={{ color: '#000', fontWeight: 'bold' }}>最新动态</div>}>
                        {
                            news !== null && news.length > 0
                                ? news.map(item => <List.Item key={item.key} onClick={() => { navigate('/club/article?relkey=' + params.relkey + '&html=' + item.html + '&key=' + item.key); }}>
                                    <Ellipsis direction='end' content={item.title} />
                                </List.Item>)
                                : <Empty description='暂无数据' />
                        }
                    </List>
                    <Button
                        disabled={params.registed === 'true'}
                        onClick={() => { onRegistClick() }}
                        block
                        color='primary'
                        style={{ marginTop: '10px' }}
                    >
                        立刻报名
                    </Button>
                </div>
                : <></>
        }
    </div >
}