import React, { useEffect } from 'react';
import queryString from 'query-string'
import './index.css';
import { useLocation } from 'react-router-dom';

export const ClubArticle: React.FC = () => {
    const location = useLocation()
    const { search } = location
    const params: any = queryString.parse(search);
    // eslint-disable-next-line
    useEffect(() => { document.title = '文章详情'; }, [])
    return <div style={{ backgroundColor: '#FFF' }}>
        <iframe title={'动态详情'} src={params.html} style={{ width: '100%', height: 'calc(100vh - 0px)', border: '0px solid #ccc' }}></iframe>
    </div >
}