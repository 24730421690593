import React, { useEffect, useState } from 'react'
import { SearchBar, PickerView, List, Grid, Modal, Button } from 'antd-mobile'

interface Props {
    title: string;
    pickerData: any;
    value: string;
    onChange: (v: string | number) => void;
    disabled?: boolean;
}

export const SearchPicker: React.FC<Props> = (props) => {
    const allData = props.pickerData;
    const [pickerDataList, setPickerDataList] = useState([]);
    const [visible, setVisiable] = useState(false);
    let tempValue = props.value;
    const onShowEnteprisePicker = () => {
        setPickerDataList(allData);
        setVisiable(true);
    }
    // eslint-disable-next-line
    useEffect(() => { setPickerDataList(allData) }, [])
    return <>
        <List.Item onClick={onShowEnteprisePicker} extra={allData !== null && allData.filter((f: any) => f.value === props.value).length > 0 ? allData.filter((f: any) => f.value === props.value)[0].label : '请选择'} clickable>
            {props.title}
        </List.Item>
        <Modal
            visible={visible}
            content={<div>
                <Grid columns={2}>
                    <Grid.Item>
                        <Button size='small' onClick={() => { setVisiable(false) }}>取消</Button>
                    </Grid.Item>
                    <Grid.Item style={{ textAlign: 'right' }}>
                        <Button size='small' color='primary' onClick={() => {
                            props.onChange(tempValue); setVisiable(false);
                        }}>确定</Button>
                    </Grid.Item>
                </Grid>
                <div style={{marginTop:'10px'}}>
                    <SearchBar placeholder='请输入内容' onSearch={(val: any) => {
                        let filterList = props.pickerData.filter((v: any) => val && val !== '' ? String(v.label).includes(val) : true);
                        setPickerDataList(filterList);
                    }} onClear={() => { setPickerDataList(allData) }} />
                    <PickerView
                        columns={[pickerDataList]}
                        value={[tempValue]}
                        onChange={(v: any) => { tempValue = v[0] }}
                    />
                </div>
            </div>}
            closeOnAction
            destroyOnClose
        />
    </>
}