import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation, useNavigate } from "react-router-dom";
import { Toast, List, Result, Avatar } from 'antd-mobile'
import { axiosPost } from '../../Utils/request';
import wx from 'weixin-js-sdk';
import './Pay.css'
import { isWxDebug } from '../../Utils/utils';
export const School: React.FC = () => {
    const [enterpriseList, setEnterpriseList] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const { search } = location
    const params = queryString.parse(search);
    const initJssdk = () => {
        if (!isWxDebug) {
            let url = window.location.href;
            var isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if (isIOS) {
                url = 'http://iss.jhxhzn.com/wechat/index?openId=' + params.openId + '&unionId=' + params.unionId + '&t=' + params.t;
            }
            const body = {
                url: url
            };
            Toast.show({ icon: 'loading', content: '加载中…', duration: 0, maskClickable: false });
            axiosPost(1, body, 'Weixin', (res: any) => {
                if (res.code === 0) {
                    initJssdkConfig(res.data);
                    return;
                }
                Toast.clear();
                Toast.show({ icon: 'fail', content: res.message, maskClickable: false });
                return;
            });
            return;
        }
        getEnterprise();
        return;
    }
    const initJssdkConfig = (options: any) => {
        try {
            options.debug = false;
            options.jsApiList = ['updateAppMessageShareData', 'hideMenuItems', 'chooseImage', 'getLocalImgData', 'chooseWXPay'];
            wx.config(options);
            wx.error((res: any) => {
                Toast.clear();
            });
            wx.ready(() => {
                wx.hideMenuItems({
                    menuList: ['menuItem:share:timeline', 'menuItem:share:qq', 'menuItem:share:weiboApp', 'menuItem:favorite', 'menuItem:share:facebook', 'menuItem:share:QZone', 'menuItem:editTag', 'menuItem:delete', 'menuItem:copyUrl', 'menuItem:originPage', 'menuItem:readMode', 'menuItem:openWithQQBrowser', 'menuItem:openWithSafari', 'menuItem:share:email', 'menuItem:share:brand'],
                    fail: (res: any) => {
                        Toast.clear();
                    },
                    success: () => {
                        wx.updateAppMessageShareData({
                            title: '在校缴费',
                            desc: '点击链接可帮助学生进行在线缴费',
                            link: 'http://api.jhxhzn.com/jumpPay.html',
                            imgUrl: 'http://api.jhxhzn.com/web/hyx.png',
                            success: () => {
                                getEnterprise();
                            },
                            fail: (res: any) => {
                                Toast.clear();
                            }
                        });
                    }
                });
            });
        }
        catch { }
    }
    const getEnterprise = () => {
        const body = {
            where: 'QRCodeFee=1'
        };
        axiosPost(48, body, 'Enterprise', (res: any) => {
            Toast.clear();
            if (res.code === 0) {
                let newList = [];
                res.data.list.forEach((item: any) => {
                    item.label = item.EnterpriseName;
                    item.value = item.EnterpriseNO;
                    newList.push(item);
                });
                setEnterpriseList(newList);
                return;
            }
            Toast.show({ icon: 'fail', content: res.message, maskClickable: false });
            return;
        })
    }
    const onItemClick = (item: any) => {
        navigate('/wechat/validate?openId=' + params.openId + '&unionId=' + params.unionId + '&enterprise=' + item.value + '&t=' + params.t);
        return;
    }
    // eslint-disable-next-line
    useEffect(() => { document.title = '在线缴费'; initJssdk(); }, [])
    return <List header='学校列表'>
        {
            enterpriseList.length > 0
                ? enterpriseList.map(item => <List.Item key={item.EnterpriseNO} prefix={<Avatar src={item.EnterpriseLogo} />} onClick={() => { onItemClick(item) }} description={'点击进入' + item.EnterpriseName + '在线缴费平台'} clickable>{item.EnterpriseName}</List.Item>)
                : <Result
                    status='info'
                    title='未开放'
                    description={'当前没有学校可进行自助注册'}
                />}
    </List>
}