import React from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from 'antd-mobile'
export const GovStudentAgreement: React.FC = () => {
    const location: any = useLocation();
    const navigate = useNavigate();
    const onAgreeClick = () => {
        navigate('/wechat/govstudent/form', { state: location.state });
    }
    return <div>
        <iframe title='agreement' src={location.state.CodeMemo} style={{ width: '100%', height: '100%', border: '0px solid #ddd' }}></iframe>
        <Button block color='primary' onClick={onAgreeClick} size='middle'>
            我已阅读并同意
        </Button>
    </div>
}