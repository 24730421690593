import React, { useEffect } from 'react';
import {
    Button,
    Cascader,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Select,
    Radio,
    Upload,
    Modal
} from 'antd';
import {
    UploadOutlined
} from '@ant-design/icons';
import { FieldModel, FieldType } from '../Utils/FieldsUtil';
import dayjs from 'dayjs';
import './FormStyle.css';
const { Option } = Select;
interface valObject {
    [key: string]: any
}
interface Values {
    title: string;
    description: string;
    modifier: string;
}

interface CollectionCreateFormProps {
    open: boolean;
    onCreate: (values: Values) => void;
    onCancel: () => void;
    fields: Array<FieldModel>,
    values: any,
    okText: string,
    cancelText: string,
    title: string,
    width: any
}

const ModalForm: React.FC<CollectionCreateFormProps> = ({
    open,
    onCreate,
    onCancel,
    fields,
    values,
    okText,
    cancelText,
    title,
    width
}) => {
    const [form] = Form.useForm();
    const onDataChange = (e: any, item: any) => {
        form.setFieldValue('id', item.enums.filter((f: any) => f._id === e)[0].phone);
        form.setFieldValue('name', item.enums.filter((f: any) => f._id === e)[0].name);
    }

    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    const genItem = (item: any) => {
        let ele = <Input />;
        if (item.type === FieldType.string) {
            ele = <Form.Item
                name={item.key}
                label={item.title}
                rules={[
                    {
                        required: item.require,
                        message: '请输入' + item.title,
                    },
                ]}
            >
                <Input disabled={item.disable} placeholder={'请输入' + item.title} />
            </Form.Item>
        }
        if (item.type === FieldType.password) {
            ele = <Form.Item
                name={item.key}
                label={item.title}
                rules={[
                    {
                        required: item.require,
                        message: '请输入' + item.title,
                    },
                ]}
            >
                <Input type='password' disabled={item.disable} placeholder={'请输入' + item.title} />
            </Form.Item>
        }
        if (item.type === FieldType.select) {
            ele = <Form.Item
                name={item.key}
                label={item.title}
                rules={[{ required: item.require, message: '请选择' + item.title }]}
            >
                <Select disabled={item.disable} placeholder={'请选择' + item.title}>
                    {item.enums.map((enumItem: any) => <Option key={enumItem.code} value={enumItem.code}>{enumItem.name}</Option>)}
                </Select>
            </Form.Item>
        }
        if (item.type === FieldType.text) {
            ele = <Form.Item
                name={item.key}
                label={item.title}
                rules={[{ required: item.require, message: '请输入' + item.title }]}
            >
                <Input.TextArea disabled={item.disable} placeholder={'请输入' + item.title} rows={2} />
            </Form.Item>
        }
        if (item.type === FieldType.date) {
            ele = <Form.Item
                name={item.key}
                label={item.title}
                rules={[{ required: item.require, message: '请选择' + item.title }]}
            >
                <DatePicker disabled={item.disable} style={{ width: '100%' }} placeholder={'请选择' + item.title} />
            </Form.Item>
        }
        if (item.type === FieldType.cascader) {
            let options: any = renderOptionItem(item.enums).filter((f: any) => f.value !== 'all');
            ele = <Form.Item
                name={item.key}
                label={item.title}
                rules={[{ required: item.require, message: '请选择' + item.title }]}
            >
                <Cascader disabled={item.disable} options={options} placeholder={'请选择' + item.title} />
            </Form.Item>
        }
        if (item.type === FieldType.checkbox) {
            ele = <Form.Item
                name={item.key}
                label={item.title}
                rules={[{ required: item.require, message: '请选择' + item.title }]}
            >
                <Radio.Group disabled={item.disable} defaultValue={item.default} value={values !== null && values[item.key] ? values[item.key] : item.default}>
                    {item.enums.map((enumItem: any) => <Radio key={enumItem.code} value={enumItem.code}>{enumItem.name}</Radio>)}
                </Radio.Group>
            </Form.Item>
        }
        if (item.type === FieldType.number) {
            ele = <Form.Item
                name={item.key}
                label={item.title}
                rules={[
                    {
                        required: item.require,
                        message: '请输入' + item.title,
                    },
                ]}
            >
                <InputNumber disabled={item.disable} style={{ width: '100%' }} placeholder={'请输入' + item.title} />
            </Form.Item>
        }
        if (item.type === FieldType.image) {
            ele = <Form.Item
                name={item.key}
                label={item.title}
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[
                    {
                        required: item.require,
                        message: '选择' + item.title,
                    },
                ]}
            >
                <Upload disabled={item.disable} maxCount={1} beforeUpload={() => { return false }}>
                    <Button disabled={item.disable} icon={<UploadOutlined />}>选择照片……</Button>
                </Upload>
            </Form.Item>
        }
        if (item.type === FieldType.dbdata) {
            ele = <Form.Item
                name={item.key}
                label={item.title}
                rules={[{ required: item.require, message: '请选择' + item.title }]}
            >
                <Select disabled={item.disable} placeholder={'请选择' + item.title}>
                    {item.enums.map((enumItem: any) => <Option value={enumItem.value}>{enumItem.text}</Option>)}
                </Select>
            </Form.Item>
            if (item.title === '所属') {
                ele = <Form.Item
                    name={item.key}
                    label={item.title}
                    rules={[{ required: item.require, message: '请选择' + item.title }]}
                >
                    <Select disabled={item.disable} onChange={(e) => { onDataChange(e, item) }} placeholder={'请选择' + item.title}>
                        {item.enums.map((enumItem: any) => <Option value={enumItem.value}>{enumItem.text}</Option>)}
                    </Select>
                </Form.Item>
            }
        }
        if (item.type === FieldType.html) {
            ele = <Form.Item
                name={item.key}
                label={item.title}
                rules={[{ required: item.require, message: '请选择' + item.title }]}
            >
                <Input disabled={item.disable} placeholder={'请输入' + item.title} />
            </Form.Item>
        }
        if (item.type === FieldType.banner) {
            ele = <Form.Item
                name={item.key}
                label={item.title}
                rules={[{ required: item.require, message: '请选择' + item.title }]}
            >
                <Input disabled={item.disable} placeholder={'请输入' + item.title} />
            </Form.Item>
        }
        if (item.type === FieldType.editor) {
            ele = <Form.Item
                name={item.key}
                label={item.title}
                rules={[{ required: item.require, message: '请输入' + item.title }]}
            >
                <Input.TextArea disabled={item.disable} placeholder={'请输入' + item.title} rows={2} />
            </Form.Item>
        }
        return <>{ele}</>
    }
    const renderOptionItem = (list: any) => {
        list.forEach((item: any) => {
            item.value = item.key;
            if (item.children && item.children.length > 0) {
                renderOptionItem(item.children);
            }
        });
        return list;
    }
    const init = () => {
        let value: valObject = {};
        fields.forEach(field => {
            if (values !== null && values[field.key]) {
                if (values[field.key] === '') {
                    value[field.key] = null;
                }
                else {
                    if (field.type === FieldType.date) {
                        value[field.key] = dayjs(values[field.key]);
                    }
                    else if (field.type === FieldType.cascader) {
                        value[field.key] = [values[field.key].substring(0, 4), values[field.key]];
                    }
                    else if (field.type === FieldType.image) {
                        value[field.key] = [{ uid: values[field.key], url: values[field.key], name: '当前照片' }];
                    }
                    else {
                        value[field.key] = values[field.key]
                    }
                }
            } else {
                value[field.key] = field.default;
            }
        });
        form.setFieldsValue(value)
    }
    useEffect(() => {
        init();
    })
    return (
        <Modal
            open={open}
            title={title}
            okText={okText}
            cancelText={cancelText}
            getContainer={false}
            destroyOnClose
            onCancel={onCancel}
            width={width ? width : '400px'}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        onCreate(values);
                    })
                    .catch((info) => {
                        console.log(info)
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
            >
                {
                    fields.map((item: any) => {
                        return genItem(item);
                    })
                }
            </Form>
        </Modal>
    );
};
export default ModalForm;
