import React, { useEffect, useState } from 'react'
import { TabBar, SearchBar, Dialog, Toast } from 'antd-mobile'
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  AppOutline,
  AppstoreOutline,
  UserOutline,
} from 'antd-mobile-icons'
import queryString from 'query-string'
import { axiosPost } from '../../Utils/request'
import './Layout.css'

const Bottom: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname, search } = location
  const [tabs, setTabs] = useState([{
    key: '/ebook/home',
    title: '书架',
    icon: <AppOutline />,
  },
  {
    key: '/ebook/category',
    title: '分类',
    icon: <AppstoreOutline />,
  },
  {
    key: '/ebook/me',
    title: '我的',
    icon: <UserOutline />,
  }])
  let params = queryString.parse(search);
  const setRouteActive = (value: string) => {
    navigate(value)
  }
  const getCurrent = () => {
    if (!search || search === '' || search == null) {
      navigate('/login')
      return;
    }
    Toast.show({ icon: 'loading', content: '加载中…', duration: 0, maskClickable: false });
    let body: any = {
      key: '',
      relkey: params.relkey,
      type: '1'
    }
    axiosPost(13, body, 'Login', (res: any) => {
      let userRels = [];
      if (res.code === 0) {
        userRels = res.data.userRels;
      }
      body.type = '2';
      axiosPost(13, body, 'Login', (res: any) => {
        Toast.clear()
        if (res.code === 0) {
          userRels = [...userRels, ...res.data.userRels];
        }
        if (userRels.length === 1) {
          setTabs([{
            key: '/ebook/home?relkey=' + userRels[0].RelKey,
            title: '书架',
            icon: <AppOutline />,
          },
          {
            key: '/ebook/category?relkey=' + userRels[0].RelKey,
            title: '分类',
            icon: <AppstoreOutline />,
          },
          {
            key: '/ebook/me?relkey=' + userRels[0].RelKey,
            title: '我的',
            icon: <UserOutline />,
          }]);
          setRouteActive('/ebook/home?relkey=' + userRels[0].RelKey);
          return;
        }
        if (userRels.length > 1) {
          let actions = [];
          userRels.forEach(item => {
            actions.push({
              key: item.RelKey,
              text: <div>
                <div>{item.EnterpriseName}</div>
                <div>{item.UserType === 5 ? '[家长用户]  ' + item.TeaName : '[管理用户]  ' + item.TeaName}</div>
              </div>,
            })
          });
          Dialog.show({
            content: '用户选择',
            closeOnAction: true,
            actions: actions,
            onAction: (action, index) => {
              setTabs([{
                key: '/ebook/home?relkey=' + action.key,
                title: '书架',
                icon: <AppOutline />,
              },
              {
                key: '/ebook/category?relkey=' + action.key,
                title: '分类',
                icon: <AppstoreOutline />,
              },
              {
                key: '/ebook/me?relkey=' + action.key,
                title: '我的',
                icon: <UserOutline />,
              }]);
              setRouteActive('/ebook/home?relkey=' + action.key);
            }
          })
          return;
        }
        setRouteActive('/login');
        return;
      })
    })
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getCurrent(); }, [])
  return (
    <TabBar activeKey={pathname + search} onChange={value => setRouteActive(value)}>
      {tabs.map(item => (
        <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
      ))}
    </TabBar>
  )
}

export const Layout: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const onSearchClick = (keyword: string) => {
    const { search } = location
    const params = queryString.parse(search);
    navigate('/ebook/list?relkey=' + params.relkey + '&keyword=' + keyword);
  }
  return (
    <div className={'app'}>
      <div className={'top'}>
        <SearchBar placeholder='请输入内容' style={{ width: '100%' }} onSearch={onSearchClick} />
      </div>
      <div className={'body'}>
        <Outlet />
      </div>
      <div className={'bottom'}>
        <Bottom />
      </div>
    </div>
  )
}