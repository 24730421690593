import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Toast, Card, Tag, Button, Result } from 'antd-mobile'
import { axiosPost, axiosPostPay } from '../../Utils/request'
import { useNavigate } from 'react-router-dom'
import wx from 'weixin-js-sdk'
import './Pay.css'
export const MulPay: React.FC = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [list, setList] = useState(location.state.list)
    const [loading, setLoading] = useState(false)
    const [isFirstPay, setFirstPay] = useState(false)

    let currentPay = ''
    let currentSet = false
    const initJssdk = () => {
        if (list.length > 0) {
            list.forEach((item: any, index: number) => {
                if (index === 0 && item.State === '2') {
                    setFirstPay(true)
                }
                if (!currentSet && item.State === '0') {
                    currentSet = true
                    currentPay = item.OrderNo
                }
            })
            let url = window.location.href
            var isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
            if (isIOS) {
                url = 'http://iss.jhxhzn.com/wechat/index?openId=' + location.state.openId + '&unionId=' + location.state.unionId + '&t=' + location.state.t
            }
            const body = { url }
            Toast.show({ icon: 'loading', content: '加载中…', duration: 0, maskClickable: false })
            axiosPost(1, body, 'Weixin', (res: any) => {
                if (res.code === 0) {
                    initJssdkConfig(res.data)
                    return
                }
                Toast.clear()
                Toast.show({ icon: 'fail', content: res.message, maskClickable: false })
                return
            })
            return
        }
        return
    }
    const initJssdkConfig = (options: any) => {
        try {
            options.debug = false
            options.jsApiList = ['updateAppMessageShareData', 'hideMenuItems', 'chooseImage', 'getLocalImgData', 'chooseWXPay']
            wx.config(options)
            wx.error((res: any) => {
                Toast.clear()
            })
            wx.ready(() => {
                wx.hideMenuItems({
                    menuList: [
                        'menuItem:share:timeline',
                        'menuItem:share:qq',
                        'menuItem:share:weiboApp',
                        'menuItem:favorite',
                        'menuItem:share:facebook',
                        'menuItem:share:QZone',
                        'menuItem:editTag',
                        'menuItem:delete',
                        'menuItem:copyUrl',
                        'menuItem:originPage',
                        'menuItem:readMode',
                        'menuItem:openWithQQBrowser',
                        'menuItem:openWithSafari',
                        'menuItem:share:email',
                        'menuItem:share:brand',
                    ],
                    fail: (res: any) => {
                        Toast.clear()
                    },
                    success: () => {
                        wx.updateAppMessageShareData({
                            title: '在校缴费',
                            desc: '点击链接可帮助学生进行在线缴费',
                            link: 'http://api.jhxhzn.com/jumpPay.html',
                            imgUrl: 'http://api.jhxhzn.com/web/hyx.png',
                            success: () => {
                                Toast.clear()
                                GoPayUrl(list)
                            },
                            fail: (res: any) => {
                                Toast.clear()
                            },
                        })
                    },
                })
            })
        } catch { }
    }

    const LoadMixOrder = () => {
        const body = {
            StudentKey: location.state.student,
            enterprise: location.state.enterprise,
            openId: location.state.openId,
        }
        axiosPost(30, body, 'FreeManager', (res: any) => {
            switch (res.code) {
                case 0:
                    if (res.data.list[0].State === '2') {
                        setFirstPay(true)
                    } else {
                        setFirstPay(false)
                    }
                    setList(res.data.list)
                    return
                case 1:
                    if (isFirstPay === true) {
                        setList([])
                    } else {
                        Toast.show({ content: res.message })
                        navigate(-1)
                    }
                    break
            }
        })
    }

    const onClickPayItem = (item: any) => {
        currentPay = item.OrderNo
        GoPayUrl(list)
    }

    const GoPayUrl = (serverList: any) => {
        setLoading(true)
        let url = serverList.filter((f: any) => f.OrderNo === currentPay)[0].Url
        if (url.indexOf('https://mobile.abchina.com') >= 0) {
            window.location.href = url
            setLoading(false)
            return
        }

        const payParams = {
            enterprise: location.state.enterprise,
            student: location.state.student,
            free: currentPay,
            openId: location.state.openId,
        }
        axiosPostPay(27, payParams, 'Wechat', (res: any) => {
            if (res.code === 0) {
                var resJson = JSON.parse(res.data.json)
                wx.chooseWXPay({
                    timestamp: resJson.timeStamp,
                    nonceStr: resJson.nonceStr,
                    package: resJson.package,
                    signType: resJson.signType,
                    paySign: resJson.paySign,
                    success: function (res: any) {
                        console.log(res)
                        return
                    },
                    fail: function (res: any) {
                        console.log('fail:', res)
                        return
                    },
                    complete: function (res: any) {
                        Toast.clear()
                        Toast.show({ icon: 'loading', content: '支付确认中', duration: 0, maskClickable: false })
                        setTimeout(() => {
                            LoadMixOrder()
                            Toast.clear()
                            setLoading(false)
                        }, 3000)
                        return
                    },
                    cancel: function (res: any) {
                        console.log('cancel:', res)
                        return
                    },
                })
                return
            }
            setLoading(false)
            Toast.clear()
            Toast.show({ icon: 'fail', content: res.message, maskClickable: false })
            return
        })
        return
    }

    // eslint-disable-next-line
    useEffect(() => { document.title = '在线缴费'; initJssdk(); }, [])
    return (
        <div style={{ backgroundColor: '#f0f0f0', height: window.innerHeight + 'px' }}>
            <div style={{ backgroundColor: '#fff', lineHeight: '30px', fontWeight: 'bold', fontSize: '14px', padding: '10px' }}>待缴费信息</div>
            <div style={{ padding: '10px' }}>
                {list.length > 0 ? (
                    list.map((item: any, index: number) => {
                        return (
                            <Card title={<div style={{ fontWeight: 'bold' }}>{item.Title}</div>} onBodyClick={null} onHeaderClick={null} extra={item.State === '2' ? <Tag color='success'>已支付</Tag> : <Tag color='danger'>未支付</Tag>} style={{ borderRadius: '16px', marginTop: '10px' }}>
                                <div style={{ fontSize: '14px', lineHeight: '30px' }}>
                                    <span style={{ fontSize: '12px' }}>订单编号：</span>
                                    <span>{item.OrderNo}</span>
                                </div>
                                <div style={{ fontSize: '14px', lineHeight: '30px' }}>
                                    <span style={{ fontSize: '12px' }}>待缴金额：</span>
                                    <span>{item.Total}</span>
                                </div>
                                <div style={{ paddingTop: '11px', borderTop: '1px solid #e5e5e5', display: 'flex', justifyContent: 'flex-end' }} onClick={e => e.stopPropagation()}>
                                    <Button
                                        disabled={item.State === '2' || loading || (!isFirstPay && index !== 0)}
                                        loading={loading}
                                        color='primary'
                                        loadingText={item.OrderNo === currentPay ? '正在支付' : '待支付'}
                                        onClick={() => {
                                            onClickPayItem(item)
                                        }}>
                                        {item.State === '2' ? '已支付' : '待支付'}
                                    </Button>
                                </div>
                            </Card>
                        )
                    })
                ) : (
                    <Result status='success' title='完成' description='所有待支付订单已全部支付完成' />
                )}
            </div>
        </div>
    )
}
