import './App.css';
import { ConfigProvider } from 'antd';
import { Layout } from './Pages/EBook/Layout';
import { Home } from './Pages/EBook/Home';
import { Category } from './Pages/EBook/Category';
import { Me } from './Pages/EBook/Me';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Login } from './Pages/Login';
import { BookList } from './Pages/EBook/BookList';
import { Search } from './Pages/EBook/Search';
import { Preview } from './Components/Preview';
import { Index } from './Pages/EBook/Admin/Index';
import { Index as CopybookAdminIndex } from './Pages/Copybook/Admin/Index';
import { Agreement } from './Pages/Agreement';
import { History } from './Pages/EBook/History';
import { AdminHistory } from './Pages/EBook/Admin/History';
import { Validate } from './Pages/WeChat/Validate';
import { Pay } from './Pages/WeChat/Pay';
import { School } from './Pages/WeChat/School';
import { GovStudentIndex } from './Pages/WeChat/GovStudent/GovStudentIndex';
import { GovStudentAgreement } from './Pages/WeChat/GovStudent/GovStudentAgreement';
import { GovStudentLayout } from './Pages/WeChat/GovStudent/GovStudentLayout';
import { MulPay } from './Pages/WeChat/MulPay';
import { ClubIndex } from './Pages/Club/Index';
import { ClubRegist } from './Pages/Club/Regist';
import { ClubDetail } from './Pages/Club/Detail';
import { ClubArticle } from './Pages/Club/Article';
import { AdminIndex } from './Pages/Club/Admin/Index';
import zhCN from 'antd/locale/zh_CN';
import { AdminNews } from './Pages/Club/Admin/News';
import { AdminRegisted } from './Pages/Club/Admin/Registed';
import { AdminSet } from './Pages/Club/Admin/Set';
function App() {
  return (
    <div id="router">
      <ConfigProvider locale={zhCN}>
        <BrowserRouter>
          <Routes>
            <Route path="/ebook" element={<Layout />} >
              <Route path="/ebook/home" element={<Home />} />
              <Route path="/ebook/category" element={<Category />} />
              <Route path="/ebook/list" element={<BookList />} />
              <Route path="/ebook/me" element={<Me />} />
              <Route path="/ebook/search" element={<Search />} />
              <Route path="/ebook/history" element={<History />} />
            </Route>
            <Route path="/wechat">
              <Route path="/wechat/index" element={<School />} />
              <Route path="/wechat/validate" element={<Validate />} />
              <Route path="/wechat/pay" element={<Pay />} />
              <Route path="/wechat/mulpay" element={<MulPay />} />
              <Route path="/wechat/govstudent" element={<GovStudentLayout />} >
                <Route path="/wechat/govstudent/index" element={<GovStudentIndex />}></Route>
                <Route path="/wechat/govstudent/agreement" element={<GovStudentAgreement />}></Route>
              </Route>
            </Route>
            <Route path="/club">
              <Route path="/club/index" element={<ClubIndex />} />
              <Route path="/club/regist" element={<ClubRegist />} />
              <Route path="/club/detail" element={<ClubDetail />} />
              <Route path="/club/article" element={<ClubArticle />} />
              <Route path="/club/admin">
                <Route path="/club/admin/index" element={<AdminIndex />} />
                <Route path="/club/admin/news" element={<AdminNews />} />
                <Route path="/club/admin/regist" element={<AdminRegisted />} />
                <Route path="/club/admin/set" element={<AdminSet />} />
              </Route>
            </Route>
            <Route path="/ebook/admin" element={<Index />} />
            <Route path="/ebook/admin/history" element={<AdminHistory />} />
            <Route path="/login" element={<Login />} />
            <Route path="/view" element={<Preview />} />
            <Route path="/agreement" element={<Agreement />} />
            <Route path="/copybook/admin" element={<CopybookAdminIndex />} />
          </Routes>
        </BrowserRouter>
      </ConfigProvider>
    </div>
  );
}
export default App;
