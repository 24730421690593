import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation, useNavigate } from "react-router-dom";
import { Toast, List, Avatar, Result, Switch, Button, Grid, Dialog, ActionSheet, Checkbox } from 'antd-mobile'
import { axiosPost, axiosPostPay, axiosPut } from '../../Utils/request';
import { dataURItoBlob, isWxDebug, testBase64 } from '../../Utils/utils';
import { SearchPicker } from '../../Components/SearchPicker';
import wx from 'weixin-js-sdk';
import './Pay.css'
export const Pay: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { search } = location
    const params = queryString.parse(search);
    const [data, setData] = useState([]);
    const [current, setCurrent] = useState(null);
    const [entryList, setEntryList] = useState([]);
    const [freeList, setFreeList] = useState([]);
    const [isLive, setIsLive] = useState(true);
    const [currentFree, setCurrentFree] = useState(null);
    const [realFee, setRealFee] = useState(null);
    const [photo, setPhoto] = useState(null);
    const [tipMessage, setTipMessage] = useState('学校尚未配置收费方案');
    const [ways, setWays] = useState([]);
    const [wayVisible, setWayVisible] = useState(false);
    const [currentWay, setCurrentWay] = useState(null);
    const [feeTotal, setFeeTotal] = useState(0);
    const init = () => {
        Toast.show({ icon: 'loading', content: '加载中…', duration: 0, maskClickable: false });
        let body = {
            StudentKey: params.student,
            enterprise: params.enterprise,
            openId: params.openId
        }
        axiosPost(30, body, 'FreeManager', (res: any) => {
            if (res.code === 0) {
                Toast.clear();
                goMulPay(res.data.list);
                return;
            }
            axiosPost(42, body, 'FreeManager', (res: any) => {
                if (res.code === 0) {
                    let newList = [];
                    let lastPaidPlanName = '';
                    res.data.list.forEach((item: any) => {
                        if (item.PayType !== '3') {
                            item.value = item.Id;
                            item.label = item.Title;
                            newList.push(item);
                        } else if (item.PayType === '3') {
                            if (lastPaidPlanName === '') {
                                lastPaidPlanName = item.Title
                            }
                        }
                    });

                    if (newList.length === 0) {
                        Toast.clear();
                        setData([]);
                        Dialog.alert({
                            title: '暂无待缴费方案',
                            content: lastPaidPlanName !== '' ? `最近已缴费: ${lastPaidPlanName}` : null,
                            onConfirm: () => { },
                        })
                        setTipMessage('暂无待缴费方案');
                        return;
                    }

                    setData(newList);
                    let currentItem = newList[0];
                    setCurrent(currentItem);

                    let currentEntry = currentItem.PayType === '0' ? currentItem.Entry.filter((f: any) => f.IsLive === '1') : [];
                    setEntryList(currentEntry);

                    let freeList = currentItem.PayType === '0' ? getCurrentFree(currentItem, currentEntry) : [];
                    let newFreeList = [];
                    freeList.forEach((freeItem: any) => {
                        freeItem.value = freeItem.Id;
                        freeItem.label = freeItem.Title + '，减免合计：' + getSum(freeItem.Entry);
                        newFreeList.push(freeItem);
                    });
                    setFreeList(newFreeList);
                    newFreeList.length > 0 ? setCurrentFree(newFreeList[0]) : setCurrentFree(null);
                    currentItem.PayType === '0' ? newFreeList.length > 0 ? setRealFee(getSum(currentEntry) - getSum(newFreeList[0].Entry)) : setRealFee(getSum(currentEntry)) : currentItem.PayType === '1' ? setRealFee(getSum(currentItem.Special)) : setRealFee(getSum(currentItem.Supplementary));
                    initJssdk(currentEntry, newFreeList.length > 0 ? newFreeList[0] : null);
                    return;
                }
                Toast.clear();
                setData([]);
                Toast.show({ icon: 'fail', content: res.message, maskClickable: false });
                return;
            })
            return;
        });

    }
    const initJssdk = (currentEntry: any, newFree: any) => {
        if (!isWxDebug) {
            let url = window.location.href;
            var isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if (isIOS) {
                url = 'http://iss.jhxhzn.com/wechat/index?openId=' + params.openId + '&unionId=' + params.unionId + '&t=' + params.t;
            }
            const body = {
                url: url
            };
            Toast.show({ icon: 'loading', content: '加载中…', duration: 0, maskClickable: false });
            axiosPost(1, body, 'Weixin', (res: any) => {
                if (res.code === 0) {
                    initJssdkConfig(res.data, currentEntry, newFree);
                    return;
                }
                Toast.clear();
                Toast.show({ icon: 'fail', content: res.message, maskClickable: false });
                return;
            });
            return;
        }
        Toast.clear();
        getWays(currentEntry, newFree);
        return;
    }
    const initJssdkConfig = (options: any, currentEntry: any, newFree: any) => {
        try {
            options.debug = false;
            options.jsApiList = ['updateAppMessageShareData', 'hideMenuItems', 'chooseImage', 'getLocalImgData', 'chooseWXPay'];
            wx.config(options);
            wx.error((res: any) => {
                Toast.clear();
            });
            wx.ready(() => {
                wx.hideMenuItems({
                    menuList: ['menuItem:share:timeline', 'menuItem:share:qq', 'menuItem:share:weiboApp', 'menuItem:favorite', 'menuItem:share:facebook', 'menuItem:share:QZone', 'menuItem:editTag', 'menuItem:delete', 'menuItem:copyUrl', 'menuItem:originPage', 'menuItem:readMode', 'menuItem:openWithQQBrowser', 'menuItem:openWithSafari', 'menuItem:share:email', 'menuItem:share:brand'],
                    fail: (res: any) => {
                        Toast.clear();
                        Toast.show({ icon: 'fail', content: '2|' + JSON.stringify(res), maskClickable: false });
                    },
                    success: () => {
                        wx.updateAppMessageShareData({
                            title: '在校缴费',
                            desc: '点击链接可帮助学生进行在线缴费',
                            link: 'http://api.jhxhzn.com/jumpPay.html',
                            imgUrl: 'http://api.jhxhzn.com/web/hyx.png',
                            success: () => {
                                Toast.clear();
                            },
                            fail: (res: any) => {
                                Toast.clear();
                                Toast.show({ icon: 'fail', content: '3|' + JSON.stringify(res), maskClickable: false });
                            }
                        });
                    }
                });
            });
            getWays(currentEntry, newFree);
        }
        catch { }
    }
    const onDataChange = (e: any) => {
        let currentItem = data.filter((f: any) => f.value === e)[0];
        setCurrent(currentItem);

        let currentEntry = currentItem.PayType === '0' ? currentItem.Entry.filter((f: any) => f.IsSchool === '1') : [];
        if (isLive) {
            currentEntry = currentItem.PayType === '0' ? currentItem.Entry.filter((f: any) => f.IsLive === '1') : [];
        }
        setEntryList(currentEntry);

        let freeList = currentItem.PayType === '0' ? getCurrentFree(currentItem, currentEntry) : [];
        let newFreeList = [];
        freeList.forEach((freeItem: any) => {
            freeItem.value = freeItem.Id;
            freeItem.label = freeItem.Title + '，减免合计：' + getSum(freeItem.Entry);
            newFreeList.push(freeItem);
        });
        setFreeList(newFreeList);
        setFeeTotal(getFeePer(currentEntry, newFreeList.length > 0 ? newFreeList[0] : null, currentWay));
        newFreeList.length > 0 ? setCurrentFree(newFreeList[0]) : setCurrentFree(null);
        currentItem.PayType === '0' ? newFreeList.length > 0 ? setRealFee(getSum(currentEntry) - getSum(newFreeList[0].Entry)) : setRealFee(getSum(currentEntry)) : currentItem.PayType === '1' ? setRealFee(getSum(currentItem.Special)) : setRealFee(getSum(currentItem.Supplementary));
    }
    const onIsLiveChange = (e: any) => {
        setIsLive(e);
        let currentEntry = current.PayType === '0' ? current.Entry.filter((f: any) => f.IsSchool === '1') : [];
        if (e) {
            currentEntry = current.PayType === '0' ? current.Entry.filter((f: any) => f.IsLive === '1') : [];
        }
        setEntryList(currentEntry);

        let freeList = current.PayType === '0' ? getCurrentFree(current, currentEntry) : [];
        let newFreeList = [];
        freeList.forEach((freeItem: any) => {
            freeItem.value = freeItem.Id;
            freeItem.label = freeItem.Title + '，减免合计：' + getSum(freeItem.Entry);
            newFreeList.push(freeItem);
        });
        setFreeList(newFreeList);
        setFeeTotal(getFeePer(currentEntry, newFreeList.length > 0 ? newFreeList[0] : null, currentWay));
        newFreeList.length > 0 ? setCurrentFree(newFreeList[0]) : setCurrentFree(null);

        current.PayType === '0' ? newFreeList.length > 0 ? setRealFee(getSum(currentEntry) - getSum(newFreeList[0].Entry)) : setRealFee(getSum(currentEntry)) : current.PayType === '1' ? setRealFee(getSum(current.Special)) : setRealFee(getSum(current.Supplementary));
    }
    const onFreeChange = (e: any) => {
        let currentItem = freeList.filter((f: any) => f.Id === e)[0];
        setCurrentFree(currentItem);
        setFeeTotal(getFeePer(entryList, currentItem, currentWay));
        current.PayType === '0' ? setRealFee(getSum(entryList) - getSum(currentItem.Entry)) : current.PayType === '1' ? setRealFee(getSum(current.Special)) : setRealFee(getSum(current.Supplementary));
    }
    const onEntryItemChange = (e: any, item: any) => {
        let newEntry = JSON.parse(JSON.stringify(entryList));
        newEntry.forEach((ent: any) => {
            if (ent.Id === item.Id) {
                ent.IsSelectName = '未选中';
                if (e) {
                    ent.IsSelectName = '选中';
                }
            }
        });
        setEntryList(newEntry);
        current.PayType === '0' ? currentFree != null ? setRealFee(getSum(newEntry) - getSum(currentFree.Entry)) : setRealFee(getSum(newEntry)) : current.PayType === '1' ? setRealFee(getSum(current.Special)) : setRealFee(getSum(current.Supplementary));
        setFeeTotal(getFeePer(newEntry, currentFree, currentWay));
        return;
    }
    const onWayChange = (item: any) => {
        setCurrentWay(item);
        setFeeTotal(getFeePer(entryList, currentFree, item));
        return;
    }
    const onPhotoClick = () => {
        Toast.show({ icon: 'loading', content: '', duration: 0, maskClickable: false });
        wx.chooseImage({
            count: 1,
            sizeType: ['compressed'],
            sourceType: ['camera'],
            success: (res: any) => {
                wx.getLocalImgData({
                    localId: res.localIds[0],
                    success: (res: any) => {
                        Toast.clear();
                        var localData = res.localData;
                        if (localData.indexOf('data:image') !== 0) {
                            localData = 'data:image/jpeg;base64,' + localData
                        }
                        localData = localData.replace(/\r|\n/g, '').replace('data:image/jgp', 'data:image/jpeg');
                        setPhoto(localData);
                    },
                    fail: (res: any) => {
                        Toast.clear();
                        Toast.show({ icon: 'fail', content: '照片处理异常', maskClickable: false });
                    }
                });
            },
            fail: (res: any) => {
                Toast.clear();
                Toast.show({ icon: 'fail', content: '照片处理异常', maskClickable: false });
            },
            complete: (res: any) => {
                Toast.clear();
            },
            cancel: () => {
                Toast.clear();
            }
        });
    }
    const onSubmitOrder = () => {
        if (!isWxDebug && (photo === null || photo === '')) {
            Toast.show({ icon: 'fail', content: '请先拍照', maskClickable: false });
            return;
        }
        if (currentWay === null) {
            Toast.show({ icon: 'fail', content: '请先选中支付方式', maskClickable: false });
            return;
        }
        onPostOrder(currentWay);
        return;
    }
    const onPostOrder = (way: any) => {
        Toast.show({ icon: 'loading', content: '提交中……', duration: 0, maskClickable: false });
        let realFeeTotal = realFee !== null ? realFee : 0;
        try {
            let supplementaryKeyStr = '';
            if (current.PayType === '2') {
                current.Supplementary.forEach((item: any) => {
                    supplementaryKeyStr += supplementaryKeyStr === '' ? item.Id : ',' + item.Id
                });
            }

            let entryListNow = entryList.filter(f => f.IsSelect === '1' && f.IsSelectName !== '未选中');
            let entryKeyStr = '';
            entryListNow.forEach((item: any) => {
                entryKeyStr += entryKeyStr === '' ? item.Id : ',' + item.Id
            });
            const body = {
                action: 5,
                parameters: {
                    StudentKey: params.student,
                    enterprise: params.enterprise,
                    openId: params.openId,
                    OrderTotal: realFeeTotal,
                    SchemeKey: current.Id,
                    PayType: current.PayType,
                    IsLive: isLive ? '1' : '0',
                    IsSchool: isLive ? '0' : '1',
                    Type: way.key,
                    CardKey: '',
                    ReductionKey: current.PayType === '0' && currentFree !== null ? currentFree.Id.replace('NONE_ID', '') : '',
                    Commission: feeTotal,
                    EntryKey: entryKeyStr,
                    SupplementaryKey: supplementaryKeyStr
                }
            }
            let formData = new FormData();
            let imageFile = !isWxDebug ? dataURItoBlob(photo) : dataURItoBlob(testBase64);

            formData.append('file', imageFile, 'image.jpg')
            formData.append('json', JSON.stringify(body));
            axiosPut(formData, 'FreeManager', (res: any) => {
                Toast.clear();
                if (res.code === 0 && res.data.list.length === 1) {
                    if (res.data.list[0].Url.indexOf('https://mobile.abchina.com') >= 0) {
                        window.location.href = res.data.list[0].Url;
                        return;
                    }
                    const payParams = {
                        enterprise: params.enterprise,
                        student: params.student,
                        free: res.data.list[0].OrderNo,
                        openId: params.openId,
                    }
                    axiosPostPay(27, payParams, 'Wechat', (res: any) => {
                        var resJson = JSON.parse(res.data.json);
                        if (res.code === 0) {
                            wx.chooseWXPay({
                                timestamp: resJson.timeStamp,
                                nonceStr: resJson.nonceStr,
                                package: resJson.package,
                                signType: resJson.signType,
                                paySign: resJson.paySign,
                                success: function (res: any) {
                                    console.log(res);
                                    return;
                                },
                                fail: function (res: any) {
                                    console.log(res);
                                    return;
                                },
                                complete: function (res: any) {
                                    console.log(res);
                                    return;
                                },
                                cancel: function (res: any) {
                                    console.log(res);
                                    return;
                                }
                            });
                            return;
                        }
                        Toast.show({ icon: 'fail', content: res.message, maskClickable: false });
                        return;
                    });
                    return;
                }
                if (res.code === 0 && res.data.list.length > 1) {
                    goMulPay(res.data.list);
                    return;
                }
                Toast.show({ icon: 'fail', content: res.message, maskClickable: false });
                return;
            })
        } catch (e) {
            Toast.clear();
            Toast.show({ icon: 'fail', content: e, maskClickable: false });
            return;
        }
    }
    const getCurrentFree = (currentItem: any, currentEntry: any) => {
        let list = [];
        currentItem.Reduction.forEach((redItem: any) => {
            let has = false;
            let newRedItem = JSON.parse(JSON.stringify(redItem));
            newRedItem.Entry = [];
            currentEntry.forEach((currentEntrItem: any) => {
                if (redItem.Entry.filter((f: any) => f.Id === currentEntrItem.Id).length > 0) {
                    has = true;
                    redItem.Entry.forEach((redItemEntry: any) => {
                        if (redItemEntry.Id === currentEntrItem.Id) {
                            newRedItem.Entry.push(redItemEntry);
                        }
                    });
                }
            });
            if (has) {
                list.push(newRedItem);
            }
        });
        // if (list.length > 0) {
        //     list.unshift({ Id: 'NONE_ID', Title: '无减免', Entry: [] });
        // }
        return list;
    }
    const getSum = (list: any) => {
        let sum = 0;
        list.forEach((item: any) => {
            if (item.IsSelectName !== '未选中') {
                sum += item.Price;
            }
        });
        return sum;
    }
    const getFeePer = (list: any, curfree: any, way: any) => {
        let sum = 0;
        list.forEach((item: any) => {
            if (item.IsSelectName !== '未选中') {
                let perFee = 0;
                let has = false;
                if (curfree !== null) {
                    curfree.Entry.forEach((free: any) => {
                        if (free.Id === item.Id) {
                            let currentMiss = free.Commission.filter((f: any) => f.Type === way.PayType + '');
                            perFee = currentMiss.length > 0 ? currentMiss[0].Total : 0;
                            has = true;
                        }
                    });
                }
                if (!has) {
                    let currentMss = item.Commission.filter((f: any) => f.Type === way.PayType + '');
                    perFee = currentMss.length > 0 ? currentMss[0].Total : 0;
                }
                sum += perFee;
            }
        });
        return sum;
    }
    const goMulPay = (list: any) => {
        navigate('/wechat/mulpay', { state: { list: list, openId: params.openId, unionId: params.unionId, t: params.t, student: params.student, enterprise: params.enterprise } });
    }
    const getWays = (currentEntry: any, newFree: any) => {
        const body = {
            enterprise: params.enterprise,
            func: '02'
        }
        axiosPost(16, body, 'Order', (res: any) => {
            if (res.code === 0) {
                let newList = [];
                res.data.list.forEach(item => {
                    let title = item.PayTypeName;
                    item.label = item.PayTypeName;
                    item.value = item.PayType;
                    item.key = item.PayType;
                    item.text = title;
                    newList.push(item)
                });
                setWays(newList);
                setCurrentWay(newList[0]);
                setFeeTotal(getFeePer(currentEntry, newFree, newList[0]));
                return;
            }
            setWays([]);
            return;
        });
    }
    // eslint-disable-next-line
    useEffect(() => { document.title = '在线缴费'; init() }, []);
    return <div>
        <List header='缴费信息'>
            <List.Item
                prefix={<Avatar src={'http://image.jhxhzn.com/DataImages/' + params.student + '.jpg'} />}
                description={<div style={{ fontSize: '12px', height: '25px', lineHeight: '25px', overflow: 'hidden' }}>
                    <div>{params.ename}，{params.orgName}{data.length > 1 ? '，存在多个缴费，可点击切换' : null}</div>
                </div>}
            >
                {params.name}
            </List.Item>
        </List>
        {
            data.length > 0
                ? <div>
                    <List style={{ overflowY: 'auto', height: window.innerHeight - 165 + 'px' }}>
                        <SearchPicker pickerData={data} value={current.value} disabled={false} title={'缴费方案'} onChange={onDataChange} />
                        <List.Item>
                            <div style={{ width: '100%', color: '#333', textAlign: 'left', fontSize: '12px', lineHeight: '25px', fontWeight: 'bold', borderBottom: '1px solid #eee' }}>缴费开放时间：{current.BeginTime} ~ {current.EndTime}</div>
                            <div style={{ width: '100%', color: '#333', textAlign: 'left', fontSize: '12px', marginTop: '10px' }}>{current.Remark}</div>
                        </List.Item>
                        {
                            current.PayType === '0'
                                ? <>
                                    <List.Item extra={<Switch onChange={onIsLiveChange} checked={isLive} checkedText="是" uncheckedText="否" />}>是否住校</List.Item>
                                    <List.Item className='feePro'>
                                        <div>费用分项</div>
                                        <div style={{ width: '100%', color: '#333', textAlign: 'left', fontSize: '14px', marginTop: '10px', lineHeight: '25px' }}>
                                            <Grid className='grid-table' columns={4} style={{ width: '100%' }}>
                                                <Grid.Item className='grid-table-header'>#</Grid.Item>
                                                <Grid.Item className='grid-table-header'>序号</Grid.Item>
                                                <Grid.Item className='grid-table-header'>名称</Grid.Item>
                                                <Grid.Item className='grid-table-header'>金额</Grid.Item>
                                                {
                                                    entryList.map((item: any, index: number) => <>
                                                        <Grid.Item className='grid-table-td'><Checkbox checked={item.IsSelectName !== '未选中'} disabled={item.IsSelect === '0'} onChange={(e) => { onEntryItemChange(e, item) }} /></Grid.Item>
                                                        <Grid.Item className='grid-table-td'>{index + 1}</Grid.Item>
                                                        <Grid.Item className='grid-table-td'>{item.Title}</Grid.Item>
                                                        <Grid.Item className='grid-table-td'>{item.Price}</Grid.Item>
                                                    </>)
                                                }
                                                <Grid.Item className='grid-table-td'></Grid.Item>
                                                <Grid.Item className='grid-table-td'>{entryList.length + 1}</Grid.Item>
                                                <Grid.Item className='grid-table-td'>{'合计'}</Grid.Item>
                                                <Grid.Item className='grid-table-td'>{getSum(entryList)}</Grid.Item>
                                            </Grid>
                                        </div>
                                    </List.Item>
                                    {freeList.length > 0 ? <SearchPicker pickerData={freeList} value={currentFree !== null ? currentFree.Id : null} disabled={false} title={'减免方案'} onChange={onFreeChange} /> : null}
                                </>
                                : current.PayType === '1'
                                    ? <List.Item className='feePro'>
                                        <div>费用分项</div>
                                        <div style={{ width: '100%', color: '#333', textAlign: 'left', fontSize: '14px', marginTop: '10px', lineHeight: '25px' }}>
                                            <Grid className='grid-table' columns={4} style={{ width: '100%' }}>
                                                <Grid.Item className='grid-table-header'>#</Grid.Item>
                                                <Grid.Item className='grid-table-header'>序号</Grid.Item>
                                                <Grid.Item className='grid-table-header'>名称</Grid.Item>
                                                <Grid.Item className='grid-table-header'>金额</Grid.Item>
                                                {
                                                    current.Special.map((item: any, index: number) => <>
                                                        <Grid.Item className='grid-table-td'><Checkbox checked={item.IsSelectName !== '未选中'} disabled={item.IsSelect === '0'} onChange={(e) => { onEntryItemChange(e, item) }} /></Grid.Item>
                                                        <Grid.Item className='grid-table-td'>{index + 1}</Grid.Item>
                                                        <Grid.Item className='grid-table-td'>{item.Title}</Grid.Item>
                                                        <Grid.Item className='grid-table-td'>{item.Price}</Grid.Item>
                                                    </>)
                                                }
                                                <Grid.Item className='grid-table-td'></Grid.Item>
                                                <Grid.Item className='grid-table-td'>{current.Special.length + 1}</Grid.Item>
                                                <Grid.Item className='grid-table-td'>{'合计'}</Grid.Item>
                                                <Grid.Item className='grid-table-td'>{getSum(current.Special)}</Grid.Item>
                                            </Grid>
                                        </div>
                                    </List.Item>
                                    : <List.Item className='feePro'>
                                        <div>费用分项</div>
                                        <div style={{ width: '100%', color: '#333', textAlign: 'left', fontSize: '14px', marginTop: '10px', lineHeight: '25px' }}>
                                            <Grid className='grid-table' columns={4} style={{ width: '100%' }}>
                                                <Grid.Item className='grid-table-header'>#</Grid.Item>
                                                <Grid.Item className='grid-table-header'>序号</Grid.Item>
                                                <Grid.Item className='grid-table-header'>名称</Grid.Item>
                                                <Grid.Item className='grid-table-header'>金额</Grid.Item>
                                                {
                                                    current.Supplementary.map((item: any, index: number) => <>
                                                        <Grid.Item className='grid-table-td'><Checkbox checked={item.IsSelectName !== '未选中'} disabled={item.IsSelect === '0'} onChange={(e) => { onEntryItemChange(e, item) }} /></Grid.Item>
                                                        <Grid.Item className='grid-table-td'>{index + 1}</Grid.Item>
                                                        <Grid.Item className='grid-table-td'>{item.Title}</Grid.Item>
                                                        <Grid.Item className='grid-table-td'>{item.Price}</Grid.Item>
                                                    </>)
                                                }
                                                <Grid.Item className='grid-table-td'></Grid.Item>
                                                <Grid.Item className='grid-table-td'>{current.Supplementary.length + 1}</Grid.Item>
                                                <Grid.Item className='grid-table-td'>{'合计'}</Grid.Item>
                                                <Grid.Item className='grid-table-td'>{getSum(current.Supplementary)}</Grid.Item>
                                            </Grid>
                                        </div>
                                    </List.Item>
                        }
                        <List.Item extra={realFee}>总计</List.Item>
                        <List.Item>
                            <Grid columns={ways.length}>
                                {
                                    ways.map((item: any) => {
                                        return <Grid.Item style={{ padding: '5px' }}>
                                            <div onClick={() => { onWayChange(item) }} style={{ backgroundColor: currentWay !== null && currentWay.PayType === item.PayType ? '#0088cc' : '#f0f0f0', color: currentWay !== null && currentWay.PayType === item.PayType ? '#fff' : '#000', padding: '5px', borderRadius: '5px 5px 5px 5px' }}>
                                                <div style={{ textAlign: 'center', height: '32px', lineHeight: '32px', backgroundImage: item.PayType === 1 ? 'url(http://image.jhxhzn.com/icons/paywx.png)' : 'url(http://image.jhxhzn.com/icons/payabc.png)', backgroundPosition: '13% center', backgroundRepeat: 'no-repeat', backgroundSize: '27px 27px' }}>
                                                    {item.PayTypeName}
                                                </div>
                                            </div>
                                        </Grid.Item>
                                    })
                                }

                            </Grid>
                        </List.Item>
                        <List.Item extra={feeTotal}>手续费</List.Item>
                        <List.Item extra={realFee + feeTotal}>实际缴纳</List.Item>
                        <List.Item style={{ width: '100%' }}>
                            <div style={{ width: '100%', height: '30px', lineHeight: '30px', textAlign: 'center', color: '#666', fontSize: '14px' }}>
                                为了您的资金安全，请点击下方区域拍摄本人照片
                            </div>
                            <div style={{ width: '121px', height: '121px', margin: 'auto', textAlign: 'center', color: '#0088cc', borderRadius: '60px', border: '1px solid #eee', backgroundColor: '#f0f0f0' }} onClick={onPhotoClick}>
                                {photo === null ? <span color='#0088cc'><br />点击拍摄<br />现场照片</span> : <img src={photo} alt='' style={{ width: '120px', height: '120px', borderRadius: '60px 60px 60px 60px' }} />}
                            </div>
                        </List.Item>
                    </List>
                    <div style={{ padding: '10px' }}>
                        <Button block color='primary' onClick={onSubmitOrder} size='middle'>提交</Button>
                    </div>
                </div>
                : <Result
                    status='info'
                    title='信息提示'
                    description={tipMessage}
                />
        }
        <ActionSheet
            visible={wayVisible}
            actions={ways}
            onClose={() => setWayVisible(false)}
            onAction={action => {
                onPostOrder(action);
                setWayVisible(false);
            }}
        />
    </div>
}