import React, { useEffect } from 'react'
import { NavBar } from 'antd-mobile'
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import queryString from 'query-string';
import './GovStudentLayout.css'

export const GovStudentLayout: React.FC = () => {
    const location: any = useLocation();
    const navigate = useNavigate();
    const { search } = location
    const params = queryString.parse(search);
    // eslint-disable-next-line
    useEffect(() => { document.title = '教育局在线登记平台'; navigate('/wechat/govstudent/index', { state: params }) }, [])
    return (
        <div className={'app-stu'}>
            <div className={'top-stu'}>
                <NavBar left={null}>教育局在线登记平台</NavBar>
            </div>
            <div className={'body-stu'}>
                <Outlet />
            </div>
        </div>
    )
}