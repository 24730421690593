export const ChannelType = [{
    key: "51C0A7BF-5FB1-4256-8EAC-889C838066E1",
    value: "51C0A7BF-5FB1-4256-8EAC-889C838066E1",
    id: "51C0A7BF-5FB1-4256-8EAC-889C838066E1",
    code: "51C0A7BF-5FB1-4256-8EAC-889C838066E1",
    name: "篆书",
    label: "篆书",
    text: "篆书 ",
    children: [],
    icon: ''
},{
    key: "B3A03E99-45BA-4855-80BF-AB9EDBEBAA40",
    value: "B3A03E99-45BA-4855-80BF-AB9EDBEBAA40",
    id: "B3A03E99-45BA-4855-80BF-AB9EDBEBAA40",
    code: "B3A03E99-45BA-4855-80BF-AB9EDBEBAA40",
    name: "隶书",
    label: "隶书",
    text: "隶书 ",
    children: [],
    icon: ''
},{
    key: "1E1CFD14-A44A-4E36-997D-EA835451D728",
    value: "1E1CFD14-A44A-4E36-997D-EA835451D728",
    id: "1E1CFD14-A44A-4E36-997D-EA835451D728",
    code: "1E1CFD14-A44A-4E36-997D-EA835451D728",
    name: "草书",
    label: "草书",
    text: "草书 ",
    children: [],
    icon: ''
},{
    key: "01180E0C-1418-4AB0-B32A-4F042C24A64D",
    value: "01180E0C-1418-4AB0-B32A-4F042C24A64D",
    id: "01180E0C-1418-4AB0-B32A-4F042C24A64D",
    code: "01180E0C-1418-4AB0-B32A-4F042C24A64D",
    name: "行书",
    label: "行书",
    text: "行书 ",
    children: [],
    icon: ''
},{
    key: "0D739EA4-C793-4A0D-A631-2741246DF192",
    value: "0D739EA4-C793-4A0D-A631-2741246DF192",
    id: "0D739EA4-C793-4A0D-A631-2741246DF192",
    code: "0D739EA4-C793-4A0D-A631-2741246DF192",
    name: "楷书",
    label: "楷书",
    text: "楷书 ",
    children: [],
    icon: ''
},{
    key: "7B0FB610-1F38-40A4-B9CD-63512DE4B1F5",
    value: "7B0FB610-1F38-40A4-B9CD-63512DE4B1F5",
    id: "7B0FB610-1F38-40A4-B9CD-63512DE4B1F5",
    code: "7B0FB610-1F38-40A4-B9CD-63512DE4B1F5",
    name: "国画",
    label: "国画",
    text: "国画 ",
    children: [],
    icon: ''
},{
    key: "7B0FB610-1F38-40A4-B9CD-63512DEEEEEE",
    value: "7B0FB610-1F38-40A4-B9CD-63512DEEEEEE",
    id: "7B0FB610-1F38-40A4-B9CD-63512DEEEEEE",
    code: "7B0FB610-1F38-40A4-B9CD-63512DEEEEEE",
    name: "篆刻",
    label: "篆刻",
    text: "篆刻 ",
    children: [],
    icon: ''
}];