import { ChannelType } from "./Enums";
import { axiosPostAsync } from "./request";
export enum FieldType {
    select,
    string,
    number,
    text,
    checkbox,
    date,
    datetime,
    cascader,
    image,
    file,
    html,
    banner,
    switch,
    password,
    dbdata,
    editor
}
export enum FormType {
    copybook = 'copybook',
    copybookword = 'copybookword',
    club = "club",
}
export type FieldModel = {
    title: string,
    dataIndex: string,
    key: string,
    width: number,
    type: FieldType,
    enums?: Array<any>,
    default: any,
    require: boolean,
    disable: boolean,
    ellipsis?: boolean,
    count?: number,
    render: (text: any, item: any) => any
}

export type Props = {
    table: FormType
}
export const getFields = async (param: any) => {
    switch (param.table) {
        case FormType.copybook:
            return getCopybookFields();
        case FormType.copybookword:
            return getCopybookWordFields();
        case FormType.club:
            return await getClubFields(param.relkey, param.flag, param.model);
        default:
            return []
    }
}
const getCopybookFields = () => {
    const fields: Array<FieldModel> = [{
        title: '标题',
        dataIndex: 'name',
        key: 'name',
        width: 70,
        type: FieldType.string,
        ellipsis: true,
        default: null,
        require: true,
        disable: false,
        render: (text: any, item: any) => {
            return text;
        }
    }, {
        title: '作者',
        dataIndex: 'writerName',
        key: 'writerName',
        width: 70,
        type: FieldType.string,
        ellipsis: true,
        default: null,
        require: true,
        disable: false,
        render: (text: any, item: any) => {
            return text;
        }
    }, {
        title: '书体',
        dataIndex: 'channel',
        key: 'channel',
        width: 70,
        type: FieldType.select,
        enums: ChannelType,
        default: '',
        require: true,
        disable: false,
        render: (text: any, item: any) => {
            if (!(text !== null && text !== '')) {
                return ChannelType.filter((f: any) => f.code === text)[0].name;
            }
            return '';
        }
    }, {
        title: '简介',
        dataIndex: 'intro',
        key: 'intro',
        width: 100,
        type: FieldType.text,
        ellipsis: true,
        default: null,
        require: false,
        disable: false,
        render: (text: any, item: any) => {
            return text;
        }
    }, {
        title: '背景',
        dataIndex: 'background',
        key: 'background',
        width: 100,
        type: FieldType.text,
        ellipsis: true,
        default: null,
        require: false,
        disable: false,
        render: (text: any, item: any) => {
            return text;
        }
    }];
    return fields;
}
const getCopybookWordFields = () => {
    const fields: Array<FieldModel> = [{
        title: '汉字',
        dataIndex: 'word',
        key: 'word',
        width: 70,
        type: FieldType.string,
        ellipsis: true,
        default: null,
        require: true,
        disable: false,
        render: (text: any, item: any) => {
            return text;
        }
    }, {
        title: '书体',
        dataIndex: 'channel',
        key: 'channel',
        width: 70,
        type: FieldType.select,
        enums: ChannelType,
        default: '',
        require: true,
        disable: false,
        render: (text: any, item: any) => {
            if (!(text !== null && text !== '')) {
                return ChannelType.filter((f: any) => f.code === text)[0].name;
            }
            return '';
        }
    }];
    return fields;
}
const getClubFields = async (relkey, flag, model) => {
    const types: any = await getEnumsData(relkey, flag, model);
    const teachers:any = await getTeacherData(relkey, flag, model);
    const fields: Array<FieldModel> = [{
        title: '类型',
        dataIndex: 'type',
        key: 'type',
        width: 70,
        type: FieldType.select,
        enums: types,
        default: '01',
        require: true,
        disable: false,
        render: (text: any, item: any) => {
            if (!(text !== null && text !== '')) {
                return types.filter((f: any) => f.value === text)[0].label;
            }
            return '';
        }
    }, {
        title: '名称',
        dataIndex: 'title',
        key: 'title',
        width: 70,
        type: FieldType.string,
        ellipsis: true,
        default: null,
        require: true,
        disable: false,
        render: (text: any, item: any) => {
            return text;
        }
    }, {
        title: '教师',
        dataIndex: 'teacher',
        key: 'teacher',
        width: 70,
        type: FieldType.select,
        enums: teachers,
        default: '',
        require: true,
        disable: false,
        render: (text: any, item: any) => {
            if (!(text !== null && text !== '')) {
                return teachers.filter((f: any) => f.value === text)[0].label;
            }
            return '';
        }
    }, {
        title: '人数',
        dataIndex: 'count',
        key: 'count',
        width: 70,
        type: FieldType.number,
        default: 50,
        require: true,
        disable: false,
        render: (text: any, item: any) => {
            return text;
        }
    }, {
        title: '简介',
        dataIndex: 'desc',
        key: 'desc',
        width: 100,
        type: FieldType.text,
        ellipsis: true,
        default: null,
        require: false,
        disable: false,
        render: (text: any, item: any) => {
            return text;
        }
    }, {
        title: '封面',
        dataIndex: 'image',
        key: 'image',
        width: 50,
        type: FieldType.image,
        default: null,
        require: false,
        disable: false,
        render: (text: any, item: any) => {
            return text;
        }
    }, {
        title: '详细介绍',
        dataIndex: 'content',
        key: 'content',
        width: 50,
        type: FieldType.editor,
        default: null,
        require: false,
        disable: false,
        render: (text: any, item: any) => {
            return text;
        }
    }];
    return fields;
}

const getEnumsData = async (relkey, flag, model) => {
    const para = {
        relkey: relkey,
        flag: flag,
        model: model
    };
    const result: any = await axiosPostAsync(0, para, 'Data');
    result.data.data.list.map(item => {
        item.value = item.CodeALLID;
        item.label = item.CodeAllName;
        item.title = item.CodeAllName;
        item.code = item.CodeALLID;
        item.name = item.CodeAllName;
        return item;
    })
    return result.data.data.list;
}


export const getTeacherData = async (relkey, flag, model) => {
    const para = {
        relkey: relkey,
        id: '',
        flag: 'BMXX'
    };
    const result: any = await axiosPostAsync(1, para, 'Data');
    result.data.data.list.map(item => {
        item.value = item.CodeALLID;
        item.label = item.name;
        item.title = item.name;
        item.code = item.CodeALLID;
        return item;
    })
    return result.data.data.list;
}
export const getStudentData = async (relkey, flag, model) => {
    const para = {
        relkey: relkey,
        id: '',
        flag: 'HYBM'
    };
    const result: any = await axiosPostAsync(1, para, 'Data');
    result.data.data.list.map(item => {
        item.value = item.CodeALLID;
        item.label = item.name;
        item.title = item.name;
        item.code = item.CodeALLID;
        return item;
    })
    return result.data.data.list;
}
export const getClubsData = async (relkey, flag, model) => {
    const para = {
        relkey: relkey,
        keyword: '',
        index: 0,
        size: 10000
    };
    const result: any = await axiosPostAsync(0, para, 'Club');
    result.data.data.clubs.map(item => {
        item.value = item.key;
        item.label = item.title;
        item.code = item.key;
        return item;
    })
    return result.data.data.clubs;
}