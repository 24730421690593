import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useLocation } from "react-router-dom";
import { Button, Card, Col, Empty, Input, Row, Spin, message, Image, Modal, ConfigProvider, Breadcrumb, Space } from 'antd';
import { CloseOutlined, ReloadOutlined, RotateLeftOutlined, RotateRightOutlined, SwapOutlined, ZoomInOutlined, ZoomOutOutlined, EditOutlined, DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import zhCN from 'antd/locale/zh_CN';
import { axiosPost } from '../../../Utils/request';
import ModalForm from '../../../Components/ModalForm';
import { FieldModel, FormType, getFields } from '../../../Utils/FieldsUtil';
import { ChannelType } from '../../../Utils/Enums';
let index = 0;
let hasMore = true;
let keyword = '';
let currentItem: any = null;
let currentWord: any = null;
let currentOver: any = null;
export const Index: React.FC = () => {
    const location = useLocation()
    const { search } = location
    const params = queryString.parse(search);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({ total: 0, list: [] });
    const [fields, setFields] = useState(Array<FieldModel>);
    const [open, setOpen] = useState(false);
    const [boxTitle, setBoxTitle] = useState('');
    const [currentShow, setCurrentShow] = useState(null);
    const [currentShowImage, setCurrentShowImage] = useState(null);
    const [images, setImages] = useState([]);
    const [words, setWords] = useState([]);
    const [preVisable, setPreVisable] = useState(false);
    const [currentImgIndex, setCurrentImgIndex] = useState(0);
    const getData = (page: any, size: number) => {
        setLoading(true);
        let body = {
            relkey: params.relkey,
            type: '',
            keyword: keyword,
            id: '',
            index: page === null ? index : page,
            size: size
        };
        axiosPost(9, body, 'Art', (res: any) => {
            setLoading(false);
            if (res.code === 0) {
                let dataNew = [];
                res.data.list.forEach((item: any) => {
                    item.writerName = item.writer.name;
                    dataNew.push(item);
                });
                let dataNewNew = [];
                dataNew.forEach((item2: any) => {
                    let has = false;
                    data.list.forEach((item1: any) => {
                        if (item2.id === item1.id) {
                            has = true;
                        }
                    });
                    if (!has) {
                        dataNewNew.push(item2);
                    }
                });
                if (index !== 0) {
                    setData({ total: res.data.total, list: data.list.concat(dataNewNew) });
                    return;
                }
                setData({ total: res.data.total, list: dataNew });
                return;
            }
            hasMore = false;
            return;
        })
    }
    const getImage = (item: any) => {
        setLoading(true);
        let body = {
            relkey: params.relkey,
            copybook: item.id,
            id: ''
        };
        axiosPost(12, body, 'Art', (res: any) => {
            setLoading(false);
            if (res.code === 0) {
                setImages(res.data.list);
                setPreVisable(true);
                let imgItem = res.data.list[0];
                imgItem.index = 1;
                setCurrentShowImage(imgItem);
                getWord(imgItem, item);
                return;
            }
            return;
        })
    }
    const getWord = (item: any, ztItem: any) => {
        setLoading(true);
        let body = {
            relkey: params.relkey,
            copybook: ztItem !== null ? ztItem.id : currentShow.id,
            image: item.id,
            word: '',
            index: 0,
            size: 10000
        };
        axiosPost(13, body, 'Art', (res: any) => {
            setLoading(false);
            if (res.code === 0) {
                setWords(res.data.list);
                return;
            }
            setWords([]);
            return;
        })
    }
    const onSearchClick = (e: any) => {
        keyword = e.target.value;
        index = 0;
        hasMore = true;
        setData({ total: 0, list: [] });
        setLoading(true);
        getData(null, 50);
    }
    const onListScroll = () => {
        let div = document.getElementById('listDiv');
        if ((div.scrollTop + div.clientHeight >= div.scrollHeight - 5) && hasMore) {
            index++;
            setLoading(true);
            getData(null, 50);
            return;
        }
        if (div.scrollTop + div.clientHeight >= div.scrollHeight - 5) {
            message.destroy();
            message.warning('数据已加载完毕');
            return;
        }
        return;
    }
    const onItemClick = (item: any) => {
        setPreVisable(false);
        if (currentShow !== null) {
            if (currentShow !== null && item.id !== currentShow.id) {
                setCurrentImgIndex(0);
                setWords([]);
                setCurrentShow(item);
                setImages([]);
                setCurrentShowImage(null);
                getImage(item);
            }
            else {
                setPreVisable(true);
            }
        } else {
            setCurrentImgIndex(0);
            setWords([]);
            setCurrentShow(item);
            setImages([]);
            setCurrentShowImage(null);
            getImage(item);
        }
    }
    const onItemEditClick = async (item: any) => {
        currentItem = item;
        setLoading(true);
        setBoxTitle('修改字帖');
        const data = await getFields({ table: FormType.copybook });
        setFields(data);
        setLoading(false);
        setOpen(true);

    }
    const onItemRemoveClick = (item: any) => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleFilled />,
            content: '确认删除《' + item.name + '》吗？',
            onOk() {
                setLoading(true);
                let body = {
                    relkey: params.relkey,
                    key: item.id,
                }
                axiosPost(11, body, 'Art', (res: any) => {
                    if (res.code === 0) {
                        let dataNew = [];
                        data.list.forEach((item1: any) => {
                            if (item.id !== item1.id) {
                                dataNew.push(item1);
                            }
                        });
                        setData({ total: data.total, list: dataNew });
                        message.destroy();
                        message.success(res.message);
                        return;
                    }
                    message.destroy();
                    message.error(res.message);
                    return;
                })
            },
            onCancel() { },
        })
        return;
    }
    const onWordClick = (item: any, index: any) => {
        item.index = index + 1;
        setCurrentShowImage(item);
        setWords([]);
        getWord(item, null);
    }
    const onWordEditClick = async (item: any) => {
        currentWord = item;
        setBoxTitle('修改单字');
        const data = await getFields({ table: FormType.copybookword });
        setFields(data);
        setLoading(false);
        setOpen(true);
    }
    const onDataPost = (v: any) => {
        setOpen(false);
        setLoading(true);
        if (boxTitle.indexOf('字帖') >= 0) {
            let body = {
                relkey: params.relkey,
                name: v.name,
                key: currentItem.id,
                writer: v.writerName,
                tags: currentItem.tags,
                intro: v.intro === null ? '' : v.intro,
                style: currentItem.style,
                background: v.background === null ? '' : v.background,
                channel: v.channel
            };
            axiosPost(10, body, 'Art', (res: any) => {
                setLoading(false);
                if (res.code === 0) {
                    let dataNew = [];
                    data.list.forEach((item: any) => {
                        if (item.id === currentItem.id) {
                            item.name = v.name;
                            item.writerName = v.writerName;
                            item.writer = { id: item.writer.id, name: v.writerName, image: item.writer.image, desc: item.writer.desc };
                            item.intro = v.intro;
                            item.background = v.background;
                            item.channel = v.channel;
                        }
                        dataNew.push(item);

                    });
                    setData({ total: data.total, list: dataNew });
                    message.destroy();
                    message.success('修改成功');
                    return;
                }
                message.destroy();
                message.error(res.message);
                return;
            });
            return;
        } else {
            let body = {
                relkey: params.relkey,
                name: v.word,
                key: currentWord.id,
                channel: v.channel,
            };
            axiosPost(14, body, 'Art', (res: any) => {
                setLoading(false);
                if (res.code === 0) {
                    let dataNew = [];
                    words.forEach((item: any) => {
                        if (item.id === currentWord.id) {
                            item.word = v.word;
                            item.channel = v.channel
                        }
                        dataNew.push(item);

                    });
                    setWords(dataNew);
                    message.destroy();
                    message.success('修改成功');
                    return;
                }
                message.destroy();
                message.error(res.message);
                return;
            });
            return;
        }
    }
    const onCardOver = (item: any) => {
        currentOver = item;
    }
    const onCardDrag = (item: any) => {
        if (item.id === currentOver.id) {
            return;
        }
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleFilled />,
            content: '确认将《' + item.name + '》(' + item.sort + ')移动到《' + currentOver.name + '》(' + currentOver.sort + ')的位置吗？',
            onOk() {
                setLoading(true);
                let body = {
                    relkey: params.relkey,
                    from: item.id,
                    to: currentOver.id,
                    up: parseInt(item.sort) < parseInt(currentOver.sort),
                    toSort: currentOver.sort
                }
                axiosPost(15, body, 'Art', (res: any) => {
                    if (res.code === 0) {
                        let size = (index + 1) * 50;
                        getData(0, size);
                        message.destroy();
                        message.success(res.message);
                        return;
                    }
                    setLoading(false);
                    message.destroy();
                    message.error(res.message);
                    return;
                })
                return;
            },
            onCancel() { },
        });
        return;
    }
    // eslint-disable-next-line
    useEffect(() => { document.title = '字帖管理'; setLoading(true); getData(null, 50); }, []);
    return <ConfigProvider locale={zhCN}>
        <Spin spinning={loading}>
            <Card bodyStyle={{ padding: 10 }}>
                <Row gutter={10}>
                    <Col span={6}>
                        <div style={{ height: 30, padding: 10 }}>
                            <Input placeholder='请输入关键字点击Enter查询' allowClear onPressEnter={(e: any) => { onSearchClick(e); }} />
                        </div>
                        <div id="listDiv" style={{ height: window.innerHeight - 90, overflow: 'auto', padding: 10 }} onScroll={() => { onListScroll(); }}>
                            {
                                data.total > 0 ? <div>
                                    {
                                        data.list.map((item: any) => {
                                            return <Card key={item.id} hoverable draggable onDragOver={(e: any) => { onCardOver(item) }} onDragEnd={(e: any) => { onCardDrag(item) }} style={{ marginBottom: 10, border: currentShow !== null && item.id === currentShow.id ? '2px solid #dd2222' : '2px solid #ededed' }} bodyStyle={{ padding: 5 }}>
                                                <Row gutter={5}>
                                                    <Col span={18}>
                                                        <Button onClick={() => { onItemClick(item) }} type="link" style={{ width: '100%', textAlign: 'left' }}>
                                                            【{item.channel === '' ? '未知' : ChannelType.filter((f: any) => f.code === item.channel)[0].name}】{item.name.length > 12 ? item.name.substring(0, 12) + "…" : item.name}
                                                        </Button>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Row>
                                                            <Col span={12}>
                                                                <Button onClick={() => { onItemEditClick(item) }} type="default" style={{ border: '0px solid #ccc' }} icon={<EditOutlined />}></Button>

                                                            </Col>
                                                            <Col span={12}>
                                                                <Button onClick={() => { onItemRemoveClick(item) }} danger style={{ border: '0px solid #ccc' }} icon={<DeleteOutlined />}></Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>

                                            </Card>
                                        })
                                    }
                                </div> : <Empty style={{ marginTop: 10 }} description={'暂无数据'} />
                            }
                        </div>
                    </Col>
                    {
                        images.length > 0 ? <Image.PreviewGroup
                            items={images.map((imgItem: any, index: number) => {
                                return imgItem.url
                            })}
                            preview={{
                                visible: preVisable,
                                closeIcon: null,
                                destroyOnClose: true,
                                current: currentImgIndex,
                                className: 'imask',
                                onChange: (current, prevCurrent) => {
                                    setCurrentImgIndex(current);
                                    let currentImg = images[current];
                                    currentImg.index = current + 1;
                                    setCurrentShowImage(currentImg);
                                    getWord(images[current], null);
                                },
                                onVisibleChange: (visible, pre, crr) => {
                                    setPreVisable(false);
                                },
                                imageRender: (originalNode, info) => {
                                    return originalNode;
                                },
                                toolbarRender: (
                                    _,
                                    {
                                        transform: { scale },
                                        actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
                                    },
                                ) => (
                                    <div style={{ backgroundColor: '#000', borderRadius: '5px 5px 5px 5px' }}>
                                        <Space size={12} className="toolbar-wrapper">
                                            <Button style={{ color: '#fff' }} type='text' onClick={() => { onWordClick(images[currentImgIndex], currentImgIndex) }} icon={<ReloadOutlined />}>刷新</Button>
                                            <Button style={{ color: '#fff' }} type='text' onClick={onFlipY} icon={<SwapOutlined />}></Button>
                                            <Button style={{ color: '#fff' }} type='text' onClick={onFlipX} icon={<SwapOutlined />}></Button>
                                            <Button style={{ color: '#fff' }} type='text' onClick={onRotateLeft} icon={<RotateLeftOutlined />}></Button>
                                            <Button style={{ color: '#fff' }} type='text' onClick={onRotateRight} icon={<RotateRightOutlined />}></Button>
                                            <Button style={{ color: '#fff' }} type='text' onClick={onZoomOut} icon={<ZoomOutOutlined />} disabled={scale === 1} ></Button>
                                            <Button style={{ color: '#fff' }} type='text' onClick={onZoomIn} icon={<ZoomInOutlined />} disabled={scale === 50}></Button>
                                            <Button style={{ color: '#fff' }} type='text' onClick={() => { setPreVisable(false); }} icon={<CloseOutlined />}>关闭</Button>
                                        </Space>
                                    </div>
                                ),
                            }}
                        >
                            <></>
                        </Image.PreviewGroup> : <></>
                    }
                    <Col span={18}>
                        <div style={{ height: 30, padding: 10 }}>
                            {
                                currentShowImage !== null ? <Card bordered bodyStyle={{ padding: 5 }}>
                                    <Breadcrumb
                                        separator=">"
                                        style={{ color: '#000', fontWeight: 'bold' }}
                                        items={[
                                            {
                                                title: currentShow.name,
                                            },
                                            {
                                                title: '第' + currentShowImage.index + '张图片',
                                            },
                                            {
                                                title: '共' + words.length + '个字',
                                            }
                                        ]}
                                    />
                                </Card>
                                    : <Card bordered bodyStyle={{ padding: 5 }}>
                                        <Breadcrumb
                                            separator=">"
                                            style={{ color: '#000', fontWeight: 'bold' }}
                                            items={[
                                                {
                                                    title: '请选择图片',
                                                }
                                            ]}
                                        />
                                    </Card>
                            }
                        </div>
                        {
                            words.length > 0 ? <div style={{ height: window.innerHeight - 90, overflow: 'auto', overflowX: 'hidden', padding: 10 }}>
                                <Row gutter={10}>
                                    {words.map((item: any, index: any) => <Col key={'w-' + currentImgIndex + '-' + index} span={2} style={{ marginBottom: 10 }}>
                                        <Card bordered bodyStyle={{ padding: 0 }} style={{ borderRadius: 10, borderWidth: '2px' }}>
                                            <Image
                                                src={currentShow.time >= '2024-03-01 00:00:00' ? item.image + "_thumb.png" : item.image}
                                                width={'100%'}
                                                style={{ borderRadius: '8px 8px 0px 0px' }}
                                                height={80}
                                                fallback={item.image}
                                                preview={{
                                                    src: item.image
                                                }}
                                            />
                                            <Row style={{ backgroundColor: '#f8f8f8', borderRadius: '0px 0px 8px 8px' }}>
                                                <Col span={8} style={{ height: 30, lineHeight: '32px', paddingLeft: '5px' }}>
                                                    {item.word}
                                                </Col>
                                                <Col span={16} style={{ textAlign: 'right' }}>
                                                    <Button onClick={() => { onWordEditClick(item) }} type='link'>修改</Button>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                    )}
                                </Row>
                            </div> : <Empty style={{ marginTop: 10 }} description={'暂无数据'} />
                        }
                    </Col>
                </Row>
            </Card>
            <ModalForm
                title={boxTitle}
                open={open}
                onCreate={(v: any) => { onDataPost(v); }}
                okText='确认'
                cancelText='取消'
                width={'40%'}
                onCancel={() => {
                    setOpen(false);
                }}
                values={boxTitle.indexOf('字帖') >= 0 ? currentItem : currentWord}
                fields={fields}
            />

        </Spin>
    </ConfigProvider>
}