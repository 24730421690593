import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useLocation, useNavigate } from "react-router-dom";
import { Toast, Result, Grid, Image } from 'antd-mobile';
import { axiosPost } from '../../Utils/request';
export const Category: React.FC = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { search } = location
    const params = queryString.parse(search);
    const [data, setData] = useState([]);
    const gridCount = window.innerWidth > 800 ? window.innerWidth > 1200 ? window.innerWidth > 1600 ? 16 : 12 : 8 : 3;
    const getData = () => {
        let body = {
            relkey: params.relkey,
        };
        Toast.show({ icon: 'loading', content: '加载中…', duration: 0 });
        axiosPost(21, body, 'Book', (res: any) => {
            Toast.clear();
            if (res.code === 0) {
                setData(res.data.list);
                return;
            }
            setData([]);
            return;
        })
    }
    const onTypeClick = (item: any) => {
        navigate('/ebook/list?relkey=' + params.relkey + '&id=' + item.Id);
    }
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return data.length > 0
        ? <div style={{ textAlign: 'center', width: '100%' }}>
            <Grid columns={gridCount} gap={8} style={{ paddingBottom: 10 }}>
                {
                    data.map(item => <Grid.Item key={item.Id} onClick={() => { onTypeClick(item) }}>
                        <div style={{ textAlign: 'center', width: '100%' }}>
                            <Image
                                src={item.Image}
                                width={'100%'}
                                height={100}
                                fit='fill'
                                style={{ borderRadius: '4px 4px 0px 0px', textAlign: 'center' }}
                            />
                        </div>
                        <div style={{ textAlign: 'center', width: '100%', backgroundColor: '#f0f0f0', borderRadius: '0px 0px 4px 4px' }}>
                            <div style={{ paddingTop: 10, textAlign: 'center', width: '100%', fontWeight: 'bold', lineHeight: '30px' }}>{item.Content}</div>
                            <div style={{ paddingBottom: 10, textAlign: 'center', width: '100%' }}>{item.Total}本</div>
                        </div>
                    </Grid.Item>)
                }
            </Grid>
        </div >
        : <Result
            status='warning'
            title='提示'
            style={{ textAlign: 'center', width: '100%' }}
            description={<div>
                <div>未查询到数据</div>
            </div>
            }
        />
}