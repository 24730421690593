/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Row, Col, Select, TreeSelect, InputNumber, DatePicker, Upload,ConfigProvider } from 'antd';
import BraftEditor from 'braft-editor';
import { ContentUtils } from 'braft-utils';
import dayjs from 'dayjs';
import locale from 'antd/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import './FormStyle.css';
import 'braft-editor/dist/index.css'
dayjs.locale('zh-cn');
const tailLayout = {
    wrapperCol: {
        offset: 0,
        span: 24,
    },
};
const { SHOW_PARENT } = TreeSelect;
export const EditorForm: React.FC<any> = (props) => {
    const [form] = Form.useForm();
    const [uploadField, setUploadField] = useState(null);
    const controls: any = [
        'undo', 'redo', 'separator',
        'font-size', 'line-height', 'letter-spacing', 'separator',
        'text-color', 'bold', 'italic', 'underline', 'strike-through', 'separator',
        'superscript', 'subscript', 'remove-styles', 'emoji', 'separator', 'text-indent', 'text-align', 'separator',
        'headings', 'list-ul', 'list-ol', 'blockquote', 'code', 'separator',
        'link', 'hr', 'media', 'separator',
        'clear', 'fullscreen', 'separator'
    ];
    const onFinish = (values) => {
        values.key = props.currentItem && props.currentItem.key ? props.currentItem.key : '';
        props.onFinish(values);
    };
    const uploadFiles = {
        accept: 'image/*',
        name: 'file',
        action: 'http://47.108.63.75:41712/api/File',
        showUploadList: false,
        onChange: info => {
            if (info.file.status === 'done') {
                const url = info.file.response.data.data[0];
                let values = form.getFieldsValue();
                if (values[uploadField.id]) {
                    values[uploadField.id] = ContentUtils.insertMedias(values[uploadField.id], [
                        {
                            type: 'IMAGE',
                            url: url
                        },
                    ]);
                    form.setFieldsValue(values);
                } else {
                    let htmlValue = {};
                    htmlValue[uploadField.id] = ContentUtils.insertMedias(BraftEditor.createEditorState(null), [
                        {
                            type: 'IMAGE',
                            url: url
                        },
                    ]);
                    form.setFieldsValue(htmlValue);
                }
            }
        },
    };
    const uploadFiles1 = {
        accept: 'video/*',
        name: 'file',
        action: 'http://47.108.63.75:41712/api/File',
        showUploadList: false,
        onChange: info => {
            if (info.file.status === 'done') {
                const url = info.file.response.data.data[0];
                let values = form.getFieldsValue();
                if (values[uploadField.id]) {
                    values[uploadField.id] = ContentUtils.insertMedias(values[uploadField.id], [
                        {
                            type: 'VIDEO',
                            url: url
                        },
                    ]);
                    form.setFieldsValue(values);
                } else {
                    let htmlValue = {};
                    htmlValue[uploadField.id] = ContentUtils.insertMedias(BraftEditor.createEditorState(null), [
                        {
                            type: 'VIDEO',
                            url: url
                        },
                    ]);
                    form.setFieldsValue(htmlValue);
                }
            }
        },
    };
    const uploadFiles2 = {
        accept: '*/*',
        name: 'file',
        action: 'http://47.108.63.75:41712/api/File',
        showUploadList: false,
        onChange: info => {
            if (info.file.status === 'done') {
                const url = info.file.response.data.data[0];
                let values = form.getFieldsValue();
                if (values[uploadField.id]) {
                    values[uploadField.id] = ContentUtils.insertText(values[uploadField.id], info.file.name,
                        ['COLOR-00F', 'UNDERLINE'],
                        {
                            type: 'LINK',
                            mutability: 'MUTABLE',
                            data: {
                                href: url,
                                target: '_blank',
                            },
                        }
                    );
                    form.setFieldsValue(values);
                } else {
                    let htmlValue = {};
                    htmlValue[uploadField.id] = ContentUtils.insertText(BraftEditor.createEditorState(null), info.file.name,
                        ['COLOR-00F', 'UNDERLINE'],
                        {
                            type: 'LINK',
                            mutability: 'MUTABLE',
                            data: {
                                href: url,
                                target: '_blank',
                            },
                        }
                    );
                    form.setFieldsValue(htmlValue);
                }
            }
        },
    };
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    const extendControls: any = field => [
        {
            key: 'antd-uploader',
            type: 'component',
            component: (
                <>
                    <Upload {...uploadFiles}>
                        <button
                            type="button" className="control-item button upload-button" data-title="插入图片"
                            onClick={() => setUploadField(field)}
                        >
                            图片
                        </button>
                    </Upload>
                </>
            ),
        }, {
            key: 'antd-uploader1',
            type: 'component',
            component: (
                <Upload {...uploadFiles1}>
                    <button
                        type="button" className="control-item button upload-button" data-title="插入视频"
                        onClick={() => setUploadField(field)}
                    >
                        视频
                    </button>
                </Upload>
            ),
        }, {
            key: 'antd-uploader2',
            type: 'component',
            component: (
                <Upload {...uploadFiles2}>
                    <button
                        type="button" className="control-item button upload-button" data-title="上传附件"
                        onClick={() => setUploadField(field)}
                    >
                        附件
                    </button>
                </Upload>
            ),
        }
    ];
    useEffect(() => {
        if (props.currentItem) {
            if (props.fields.filter(f => f.type === 'html').length > 0) {
                props.fields.filter(f => f.type === 'html').forEach(field => {
                    props.currentItem[field.id] = BraftEditor.createEditorState(props.currentItem[field.id]);
                })
            }
            form.setFieldsValue(props.currentItem);
        }
    }, [])
    return (
        <>
            <Form.Provider>
                <Form form={form} onFinish={onFinish} style={{ marginTop: 0 }}>
                    <Row gutter={10}>
                        {props.fields.map(field =>
                            <Col key={field.id} span={field.id === 'title' || field.id === 'desc' || field.id === 'summary' || field.id === 'content' || field.id === 'student' || field.id === 'channelName' || field.id === 'parentId' || field.id === 'linkUrl' || field.id === 'description' || field.id === 'start' || field.id === 'end' || field.id === 'clubs' ? 24 : 6}>
                                <Form.Item
                                    labelCol={{ span: field.id === 'title' || field.id === 'desc' || field.id === 'summary' || field.id === 'content' || field.id === 'student' || field.id === 'channelName' || field.id === 'parentId' || field.id === 'linkUrl' || field.id === 'description' || field.id === 'start' || field.id === 'end' || field.id === 'clubs' ? 2 : 8 }}
                                    name={field.id}
                                    labelAlign='left'
                                    style={{ width: '100%' }}
                                    label={field.name}
                                    valuePropName={field.type === 'file' ? 'fileList' : 'value'}
                                    getValueFromEvent={(e) => {
                                        if (field.type === 'file') {
                                            return normFile(e);
                                        }
                                        if (field.type === 'string' || field.type === 'text') {
                                            return e.target.value;
                                        }
                                        return e;
                                    }}
                                    rules={[
                                        {
                                            required: field.required,
                                        },
                                    ]}
                                >
                                    {
                                        field.type === 'string'
                                            ? <Input disabled={field.disabled ? true : false} style={{ width: '100%' }} />
                                            : field.type === 'text'
                                                ? <Input.TextArea rows={2} style={{ width: '100%' }} />
                                                : field.type === 'number'
                                                    ? <InputNumber style={{ width: '100%' }} />
                                                    : field.type === 'date'
                                                        ? <ConfigProvider locale={locale}><DatePicker style={{ width: '100%' }} /></ConfigProvider>
                                                        : field.type === 'datetime'
                                                            ? <DatePicker showTime style={{ width: '100%' }} />
                                                            : field.type === 'select'
                                                                ? <Select
                                                                    style={{ width: '100%' }}
                                                                    showSearch
                                                                    filterOption
                                                                    optionFilterProp="label"
                                                                    onSearch={() => { }}
                                                                    options={field.options}
                                                                    mode={field.mode}
                                                                />
                                                                : field.type === 'file'
                                                                    ? <Upload
                                                                        name='file'
                                                                        beforeUpload={() => false}
                                                                        multiple={field.multiple}
                                                                    >
                                                                        <Button type="default">
                                                                            选择
                                                                        </Button>
                                                                    </Upload>
                                                                    : field.type === 'tree'
                                                                        ? field.mode && field.mode === 'multiple'
                                                                            ? <TreeSelect
                                                                                style={{ width: '100%' }}
                                                                                treeData={field.options}
                                                                                placeholder={'请选择' + field.name}
                                                                                treeCheckable={true}
                                                                                showCheckedStrategy={SHOW_PARENT}
                                                                                treeDefaultExpandAll={false}
                                                                                treeCheckStrictly={field.treeCheckStrictly !== undefined ? field.treeCheckStrictly : false}
                                                                            />
                                                                            : <TreeSelect
                                                                                style={{ width: '100%' }}
                                                                                placeholder={'请选择' + field.name}
                                                                                treeData={field.options}
                                                                                treeDefaultExpandAll={false}
                                                                            />
                                                                        : field.type === 'html'
                                                                            ? <BraftEditor className='mycontent' style={{ width: '100%', border: '1px solid #D9D9D9', margin: 0, }} controls={controls} extendControls={extendControls(field)} />
                                                                            : <Input />
                                    }
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                    <Form.Item {...tailLayout}>
                        <Button style={{ width: '100%' }} htmlType="submit" type="primary">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Form.Provider>
        </>
    );
};