import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import queryString from 'query-string'
import { Avatar, Button, List, Modal, Toast, Dialog } from 'antd-mobile';
import { InformationCircleOutline, MailOutline, ClockCircleOutline, ExclamationShieldOutline } from 'antd-mobile-icons'
import { axiosPost } from '../../Utils/request';
export const Me: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { search } = location
    const params = queryString.parse(search);
    const [current, setCurrent] = useState(null);
    const getCurrent = () => {
        let body: any = {
            key: '',
            relkey: params.relkey,
            type: '1'
        }
        Toast.show({ icon: 'loading', content: '加载中…', duration: 0, maskClickable: false });
        axiosPost(13, body, 'Login', (res: any) => {
            if (res.code === 0) {
                Toast.clear();
                setCurrent(res.data.userRels[0]);
                return;
            }
            body.type = '2';
            axiosPost(13, body, 'Login', (res: any) => {
                Toast.clear();
                if (res.code === 0) {
                    setCurrent(res.data.userRels[0]);
                    return;
                }
                setCurrent(null);
                return;
            })
        })
    }
    const onHistoryClick = () => {
        navigate('/ebook/history?relkey=' + params.relkey);
    }
    const onGoLoginClick = () => {
        navigate('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { getCurrent() }, [])
    return <div style={{ textAlign: 'left', width: '100%', backgroundColor: '#fbfbfb' }}>
        {
            current === null
                ? <div style={{ textAlign: 'center', width: '100%', backgroundColor: '#fff', borderRadius: 5, paddingTop: 20, paddingBottom: 20 }}>
                    <Avatar src={''} style={{ '--size': '100px', '--border-radius': '50px', margin: 'auto' }} />
                    <Button color='primary' fill='none' onClick={onGoLoginClick}>点击登录</Button>
                </div>
                : <div style={{ textAlign: 'center', width: '100%', backgroundColor: '#fff', borderRadius: 5, paddingTop: 20, paddingBottom: 20 }}>
                    <Avatar src={'http://image.jhxhzn.com/DataImages/' + current.TeaKey + '.jpg'} style={{ '--size': '100px', '--border-radius': '50px', margin: 'auto', border: '1px solid #ddd' }} />
                    <p style={{ fontSize: '16px', fontWeight: 'bold' }}>{current.TeaName}</p>
                    <p>{current.EnterpriseName}</p>
                    <p style={{ color: '#999' }}>{current.UserType === 5 ? '家长用户' : '管理用户'} / {current.UserID}</p>

                </div>
        }
        <List
            style={{
                '--border-top': 'solid 0px var(--adm-border-color)',
                '--border-bottom': 'solid 0px var(--adm-border-color)',
                borderRadius: 5,
                marginTop: 10
            }}
        >
            <List.Item prefix={<InformationCircleOutline />} onClick={() => {
                Modal.show({
                    title: '关于我们',
                    actions: [],
                    showCloseButton: true,
                    content: (
                        <div
                            style={{
                                maxHeight: 'calc(70vh - 200px)',
                                overflowY: 'auto',
                            }}
                        >
                            <p>贵州金宏鑫科技有限公司成立于2012 年6 月。</p>
                            <p>公司总部位于贵州省贵阳市南明区花果园RI 区财富广场4号， 现有员工70 余人（ 其中研发人员21 人， 实施维护人员18 人， 市场销售37 人） ， 大学本科及以上占企业员工总数的97 ％ ， 中专（ 高中） 学历占3 ％ 。</p>
                            <p>研发团队与市场核心团队均由具有10 年以上教育行业管理软（硬） 件产品研发、销售经验及多年的一线教育教学管理与家校互动平台推广资深经验的成员组成。</p>
                            <p>现有产品“合·智能” 系列。“ 合·智能” 产品系列共分为合云校、合· 社区、合·商城、合· 旅游、合· 家居、合·课堂等六个系列分支。</p>
                            <p>公司机构设置为董事会办公室、总经理办公室、财务部、办公室、研发部、技术实施部、销售一部、销售二部、销售三部、销售四部、销售五部。业务遍及贵州省10 个地州市60 余个县市， 其中黔南州12 个县市130 余所中小学、安顺市4 个区县70 余所中小学、贵阳两城区20 余所中小学及6 所中职中专、遵义地区8 所中学， 现有学生用户超过50 万户， 涉及家长用户超过240 万户， 收费用户超过18 万户。</p>
                            <p>公司现己取得软件专利著作权15 项， 硬件专利发明4 项； 同时持有合·云校、合·社区、合·商城、合·旅游、合·家居、合· 医疗等12 个注册商标。</p>
                        </div>
                    ),
                })
            }}>
                关于我们
            </List.Item>
            <List.Item prefix={<MailOutline />} onClick={() => {
                Modal.show({
                    title: '问题与建议',
                    actions: [],
                    showCloseButton: true,
                    content: (
                        <div
                            style={{
                                maxHeight: 'calc(70vh - 200px)',
                                overflowY: 'auto',
                            }}
                        >
                            <p>公司地址：贵阳市南明区花果园财富广场4号</p>
                            <p>联系电话：0851-85913206</p>
                            <p>电子邮箱：jhxhzn@jhx.com</p>
                        </div>
                    ),
                })
            }}>
                问题与建议
            </List.Item>
            <List.Item prefix={<ClockCircleOutline />} onClick={onHistoryClick}>
                历史记录
            </List.Item>
            <List.Item prefix={<ExclamationShieldOutline />} onClick={async () => {
                const result = await Dialog.confirm({
                    content: '确定退出吗？',
                })
                if (result) {
                    navigate('/login');
                    return;
                }
                return;
            }}>
                退出登录
            </List.Item>
        </List>
    </div >
}