import React from 'react'
import { SearchBar } from 'antd-mobile';
import { useLocation, useNavigate } from "react-router-dom";
import queryString from 'query-string'
export const Search: React.FC = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { search } = location
    const params = queryString.parse(search);
    const onSearchClick = (keyword: string) => {
        navigate('/ebook/list?relkey=' + params.relkey + '&keyword=' + keyword);
    }
    return <div style={{ textAlign: 'center', width: '100%' }}>
        <SearchBar placeholder='请输入内容' onSearch={onSearchClick} />
    </div>
}