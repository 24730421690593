import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useLocation, useNavigate } from "react-router-dom";
import { Toast, Result, Button, Space, Grid, Image, Dialog } from 'antd-mobile';
import { AddOutline, DeleteOutline } from 'antd-mobile-icons'
import { axiosPost } from '../../Utils/request';
export const Home: React.FC = () => {
    const location = useLocation()
    const navigate = useNavigate();
    const { search } = location
    const params = queryString.parse(search);
    const [data, setData] = useState([]);
    const gridCount = window.innerWidth > 800 ? window.innerWidth > 1200 ? window.innerWidth > 1600 ? 16 : 12 : 8 : 3;
    const getData = () => {
        let body = {
            relkey: params.relkey,
        };
        Toast.show({ icon: 'loading', content: '加载中…', duration: 0, maskClickable: false });
        axiosPost(19, body, 'Book', (res: any) => {
            Toast.clear();
            if (res.code === 0) {
                setData(res.data.list);
                return;
            }
            setData([]);
            return;
        })
    }
    const onAddClick = () => {
        navigate('/ebook/category?relkey=' + params.relkey);
    }
    const onItemClick = (item: any) => {
        let body = {
            relkey: params.relkey,
            id: item.Book
        };
        Toast.show({ icon: 'loading', content: '查询中…', duration: 0, maskClickable: false });
        axiosPost(25, body, 'Book', (res: any) => {
            Toast.clear();
            let ext = item.AllUrl.split('.')[item.AllUrl.split('.').length - 1];
            if (ext === 'htm' || ext === 'html') {
                let urlStr = encodeURIComponent(item.AllUrl);
                navigate('/view?relkey=' + params.relkey + '&src=' + urlStr);
                return;
            }
            let viewUrl = 'http://117.187.12.43:21003/filePreview?type=' + ext + '&fileName=' + item.BookName + '&url=' + item.AllUrl;
            let urlStr = encodeURIComponent(viewUrl);
            navigate('/view?relkey=' + params.relkey + '&src=' + urlStr);
            return;
        });
    }
    const onRemoveShelfClick = async (event: any, item: any) => {
        event.stopPropagation();
        const result = await Dialog.confirm({
            content: '确定从书架中移除吗？',
        })
        if (result) {
            let body = {
                relkey: params.relkey,
                book: '',
                id: item.Id,
            };
            Toast.show({ icon: 'loading', content: '提交中…', duration: 0, maskClickable: false });
            axiosPost(22, body, 'Book', (res: any) => {
                Toast.clear();
                if (res.code === 0) {
                    Toast.show({ icon: 'success', content: '操作成功', maskClickable: false });
                    getData();
                    return;
                }
                Toast.show({ icon: 'fail', content: res.message, maskClickable: false });
                return;
            })
            return;
        }
        return;
    }
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return data.length > 0
        ? <div style={{ textAlign: 'center', width: '100%' }}>
            <Grid columns={gridCount} gap={8} style={{ paddingBottom: 10 }}>
                {
                    data.map(item => <Grid.Item key={item.Id} onClick={() => { onItemClick(item) }}>
                        <div style={{ textAlign: 'center', width: '100%' }}>
                            <Image
                                src={item.BookImage}
                                width={'100%'}
                                height={140}
                                fit='fill'
                                style={{ borderRadius: '4px 4px 0px 0px', textAlign: 'center' }}
                            />
                        </div>
                        <div style={{ textAlign: 'center', width: '100%', backgroundColor: '#f0f0f0', borderRadius: '0px 0px 4px 4px' }}>
                            <div style={{ paddingTop: 10, textAlign: 'center', width: '100%', height: '35px', overflow: 'hidden' }}>{item.BookName}</div>
                            <div style={{ paddingTop: 5, textAlign: 'center', width: '100%' }}>
                                <Button size={'mini'} onClick={(event) => { onRemoveShelfClick(event, item) }} style={{ width: '100%', borderWidth: 0, backgroundColor: '#f0f0f0' }}>
                                    <Space>
                                        <DeleteOutline />
                                    </Space>
                                </Button>
                            </div>
                        </div>
                    </Grid.Item>)
                }
            </Grid>
        </div >
        : <Result
            status='warning'
            title='提示'
            style={{ textAlign: 'center', width: '100%' }}
            description={<div>
                <div>
                    书架内容为空，请添加书目
                </div>
                <div style={{ marginTop: '10px' }}>
                    <Button color='primary' size='small' onClick={onAddClick}>
                        <Space>
                            <AddOutline />
                            <span>添加</span>
                        </Space>
                    </Button>
                </div>
            </div>
            }
        />
}