import { Breadcrumb, Button, Card, Col, Drawer, Modal, Row, Spin, Table, message } from 'antd';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { EditOutlined, DeleteOutlined, PlusOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { EditorForm } from '../../../Components/EditorForm';
import { axiosPost } from '../../../Utils/request';
import dayjs from 'dayjs';
import { getClubsData } from '../../../Utils/FieldsUtil';
let clubs = [];
export const AdminSet: React.FC = () => {
    const location = useLocation()
    const navigate = useNavigate();
    const { search } = location
    const params = queryString.parse(search);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [dwrLoading, setDwrLoading] = useState(false);
    const [dwr, setDwr] = useState({ title: '', width: 600, visible: false, content: null });
    const getData = async (index, size) => {
        setLoading(true);
        clubs = await getClubsData(params.relkey, '', params.model);
        const para = {
            relkey: params.relkey,
            club: '',
            index: index,
            size: size
        };
        axiosPost(8, para, 'Club', (res: any) => {
            setLoading(false);
            if (res.code === 0) {
                setData(res.data.list);
                return;
            }
            setData([]);
            return;
        })
    }
    const onAddClick = async () => {
        let newsField = [{
            id: 'start',
            name: '开始时间',
            required: true,
            type: 'datetime'
        }, {
            id: 'end',
            name: '结束时间',
            required: true,
            type: 'datetime'
        }, {
            id: 'clubs',
            name: '可选范围',
            required: true,
            mode: 'multiple',
            type: 'select',
            options: clubs,
        }];
        let newdwr: any = {
            title: '添加',
            visible: true,
            width: '100%',
            content: <EditorForm currentItem={null} onFinish={(v: any) => { onDataPost(v, null) }} fields={newsField} layout={{ labelCol: { span: 2 } }} />
        };
        setDwr(newdwr);
    }
    const onItemEditClick = async (item) => {
        let newItem: any = JSON.parse(JSON.stringify(item));
        newItem.start=dayjs(item.start);
        newItem.end=dayjs(item.end);
        newItem.clubs=JSON.parse(item.clubs);
        let newsField = [{
            id: 'start',
            name: '开始时间',
            required: true,
            type: 'datetime'
        }, {
            id: 'end',
            name: '结束时间',
            required: true,
            type: 'datetime'
        }, {
            id: 'clubs',
            name: '可选范围',
            required: true,
            mode: 'multiple',
            type: 'select',
            options: clubs,
        }];
        let newdwr: any = {
            title: '修改',
            visible: true,
            width: '100%',
            content: <EditorForm currentItem={newItem} onFinish={(v: any) => { onDataPost(v, newItem) }} fields={newsField} layout={{ labelCol: { span: 2 } }} />
        };
        setDwr(newdwr);
    }
    const onItemDeleteClick = (item) => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleFilled />,
            content: '确认删除吗？',
            onOk() {
                setLoading(true);
                const para = {
                    relkey: params.relkey,
                    key: item.key
                };
                axiosPost(9, para, 'Club', (res: any) => {
                    setLoading(false);
                    if (res.code === 0) {
                        message.destroy();
                        message.success(res.message);
                        getData(0, 20);
                        return;
                    }
                    message.destroy();
                    message.error(res.message);
                    return;
                })
            },
            onCancel() { },
        })
        return;
    }
    const onDataPost = (values, item) => {
        setDwrLoading(true);
        const body = {
            relkey: params.relkey,
            key: item !== null ? item.key : '',
            start: dayjs(values.start).format("YYYY-MM-DD HH:mm:ss"),
            end: dayjs(values.end).format("YYYY-MM-DD HH:mm:ss"),
            clubs: values.clubs
        }
        axiosPost(7, body, 'Club', (res: any) => {
            setDwrLoading(false);
            if (res.code === 0) {
                message.destroy();
                message.success(res.message);
                getData(0, 200);
                return;
            }
            message.destroy();
            message.error(res.message);
            return;
        })
        let newdwr: any = {
            title: '',
            visible: false,
            width: '100%',
            content: null
        };
        setDwr(newdwr);
    }
    // eslint-disable-next-line
    useEffect(() => { document.title = '报名设置'; getData(0, 200); }, [])
    return <Spin spinning={loading}>
        <div style={{ height: 'calc(100vh - 0px)', padding: 10, backgroundColor: '#f8f8f8' }}>
            <Card bodyStyle={{ padding: 10 }}>
                <Row>
                    <Col span={16}>
                        <Breadcrumb
                            separator=">"
                            style={{ lineHeight: '30px' }}
                            items={[
                                {
                                    title: '首页',
                                    path: 'index'
                                },
                                {
                                    title: '设置',
                                }
                            ]}
                            itemRender={(route, params1, routes, paths) => {
                                return <div style={{ cursor: 'pointer' }} onClick={() => {
                                    if (route.path) {
                                        navigate('/club/admin/' + route.path + '?relkey=' + params.relkey + '&module=' + params.model);
                                    }
                                    return;
                                }}>{route.title}</div>
                            }}
                        />
                    </Col>
                    <Col span={8} style={{ textAlign: 'right' }}>
                        <Button icon={<PlusOutlined />} onClick={() => { onAddClick() }} type='primary'>添加时间点</Button>
                    </Col>
                </Row>
            </Card>
            <Card bodyStyle={{ padding: 10 }} style={{ marginTop: 10, height: 'calc(100vh - 80px)' }}>
                <Table
                    rowKey={'key'}
                    bordered
                    size='small'
                    scroll={{ y: window.innerHeight - 190, x: '100%' }}
                    columns={[
                        {
                            title: '开始时间',
                            dataIndex: 'start',
                            key: 'start',
                            width:180,
                            render: (text, record) => <>{dayjs(text).format("YYYY年M月D日 HH:mm:ss")}</>,
                        },
                        {
                            title: '结束时间',
                            dataIndex: 'end',
                            key: 'end',
                            width:180,
                            render: (text, record) => <>{dayjs(text).format("YYYY年M月D日 HH:mm:ss")}</>,
                        },
                        {
                            title: '可选范围',
                            dataIndex: 'clubs',
                            key: 'clubs',
                            render: (text, record) => {
                                let clubsArray = JSON.parse(text);
                                return clubs.length > 0 && clubsArray.length > 0
                                    ? clubsArray.map((element, index) => {
                                        return index === 0
                                            ? <>{clubs.filter(f => f.key === element)[0].title}</>
                                            : <>,{clubs.filter(f => f.key === element)[0].title}</>
                                    })
                                    : <></>
                            }
                        },
                        {
                            title: '操作',
                            key: 'action',
                            width: 200,
                            fixed: 'right',
                            render: (_, item) => (
                                <Row>
                                    <Col span={24}>
                                        <Button icon={<EditOutlined />} onClick={() => { onItemEditClick(item) }} type='link'>修改</Button>
                                        <Button icon={<DeleteOutlined />} onClick={() => { onItemDeleteClick(item) }} type='link' danger>删除</Button>
                                    </Col>
                                </Row>
                            ),
                        }]}
                    pagination={{
                        current: 1,
                        pageSize: 20,
                        pageSizeOptions: [20, 50, 100, 200],
                        showTotal: (total, range) => {
                            return <>共{total}条数据，当前{range[0]}至{range[1]}条</>
                        },
                        onChange: (page, pageSize) => {
                            getData(page - 1, pageSize);
                        },
                    }}
                    dataSource={data} />
            </Card>
        </div>
        <Drawer
            title={dwr.title}
            placement="right"
            destroyOnClose
            onClose={() => { let newdrw = { ...dwr }; newdrw.visible = false; setDwr(newdrw); }}
            visible={dwr.visible}
            width={dwr.width}
            bodyStyle={{ backgroundColor: '#fff', padding: 0 }}
            className='dwr'
        >
            <Spin spinning={dwrLoading}>
                <div style={{ width: 'calc(100% - 40px)', height: '100%', padding: 16, overflow: 'hidden', overflowY: 'auto' }}>
                    {dwr.content}
                </div>
            </Spin>
        </Drawer>
    </Spin>
}