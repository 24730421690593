import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Toast, List, Avatar, Result } from 'antd-mobile'
import { axiosPost } from '../../../Utils/request';
export const GovStudentIndex: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [links, setLinks] = useState([]);
    const getLinks = () => {
        const body = {
            flag: 'BMLB'
        };
        Toast.show({ icon: 'loading', content: '加载中…', duration: 0, maskClickable: false });
        axiosPost(6, body, 'Data', (res: any) => {
            if (res.code === 0) {
                Toast.clear();
                setLinks(res.data.list)
                return;
            }
            Toast.clear();
            Toast.show({ icon: 'fail', content: res.message, maskClickable: false });
            return;
        });
    }
    const onItemClick = (item: any) => {
        navigate('/wechat/govstudent/agreement', { state: { ...item, ...location.state } });
    }
    // eslint-disable-next-line
    useEffect(() => { getLinks() }, []);
    return <>
        <List className='govstudent' style={{ '--border-top': '0px solid #ddd','--border-bottom': '0px solid #ddd','--border-inner': '0px solid #ddd',backgroundColor:'#f8f8f8' }}>
            {
                links.length > 0
                    ? links.map(item => <List.Item style={{marginBottom:'20px'}} key={item.id} prefix={<Avatar src={'http://hyx.jhxhzn.com/SystemResorces/Images/newreg.png'} />} onClick={() => { onItemClick(item) }} description={'点击进入' + item.text + '平台'} clickable>{item.text}</List.Item>)
                    : <Result
                        status='info'
                        title='未开放'
                        description={'当前不能进行自助登记'}
                    />
            }
        </List>
    </>
}