import { Breadcrumb, Button, Card, Col, Drawer, Modal, Row, Spin, Table, Upload, message } from 'antd';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DeleteOutlined, PlusOutlined, ExclamationCircleFilled, ExclamationCircleOutlined,UploadOutlined } from '@ant-design/icons';
import { EditorForm } from '../../../Components/EditorForm';
import { axiosPost, axiosPut } from '../../../Utils/request';
import { getStudentData } from '../../../Utils/FieldsUtil';
export const AdminRegisted: React.FC = () => {
    const location = useLocation()
    const navigate = useNavigate();
    const { search } = location
    const params = queryString.parse(search);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [dwrLoading, setDwrLoading] = useState(false);
    const [dwr, setDwr] = useState({ title: '', width: 600, visible: false, content: null });

    const getData = (index, size) => {
        setLoading(true);
        const para = {
            relkey: params.relkey,
            key: params.key,
            index: index,
            size: size
        };
        axiosPost(4, para, 'Club', (res: any) => {
            setLoading(false);
            if (res.code === 0) {
                setData(res.data.list);
                return;
            }
            setData([]);
            return;
        })
    }
    const onAddClick = async () => {
        setLoading(true);
        const students = await getStudentData(params.relkey, 'HYBM', params.model);
        let newsField = [{
            id: 'student',
            name: '学生',
            required: true,
            type: 'select',
            options: students
        }]
        let newdwr: any = {
            title: '添加',
            visible: true,
            width: '100%',
            content: <EditorForm currentItem={null} onFinish={(v: any) => { onDataPost(v) }} fields={newsField} layout={{ labelCol: { span: 2 } }} />
        };
        setDwr(newdwr);
        setLoading(false);
    }
    const onItemDeleteClick = (item) => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleFilled />,
            content: '确认驳回《' + item.name + '》吗？',
            onOk() {
                setLoading(true);
                const para = {
                    relkey: params.relkey,
                    key: item.key
                };
                axiosPost(3, para, 'Club', (res: any) => {
                    setLoading(false);
                    if (res.code === 0) {
                        message.destroy();
                        message.success(res.message);
                        getData(0, 100);
                        return;
                    }
                    message.destroy();
                    message.error(res.message);
                    return;
                })
            },
            onCancel() { },
        })
        return;
    }
    const onDataPost = (values) => {
        setDwrLoading(true);
        const body = {
            relkey: params.relkey,
            key: params.key,
            type: params.type,
            student: values.student,
        };
        axiosPost(2, body, 'Club', (res: any) => {
            setDwrLoading(false);
            if (res.code === 0) {
                message.destroy();
                message.success(res.message);
                getData(0, 100);
                return;
            }
            message.destroy();
            message.error(res.message);
            return;
        })
        let newdwr: any = {
            title: '',
            visible: false,
            width: '100%',
            content: null
        };
        setDwr(newdwr);
    }
    const onImportClick = (item: any) => {
        Modal.confirm({
            title: '确认',
            icon: <ExclamationCircleOutlined />,
            content: '确认导入吗？',
            onOk() {
                setLoading(true)
                let formDatas = new FormData();
                const body = {
                    action: 2,
                    parameters: {
                        relkey: params.relkey,
                    }
                }
                formDatas.append('json', JSON.stringify(body));
                formDatas.append('file', item.fileList[0].originFileObj, item.fileList[0].originFileObj.name);
                axiosPut(formDatas, 'Club', (res:any) => {
                    setLoading(false)
                    if (res.code === 0) {
                        getData(0,100);
                        message.destroy();
                        message.success(res.message);
                        return;
                    }
                    message.destroy();
                    message.error(res.message);
                    return;
                })
            },
            onCancel() { },
        })
    }
    // eslint-disable-next-line
    useEffect(() => { document.title = '报名统计'; getData(0, 100); }, [])
    return <Spin spinning={loading}>
        <div style={{ height: 'calc(100vh - 0px)', padding: 10, backgroundColor: '#f8f8f8' }}>
            <Card bodyStyle={{ padding: 10 }}>
                <Row>
                    <Col span={16}>
                        <Breadcrumb
                            separator=">"
                            style={{ lineHeight: '30px' }}
                            items={[
                                {
                                    title: '首页',
                                    path: 'index'
                                },
                                {
                                    title: params.title,
                                },
                                {
                                    title: '报名信息',
                                }
                            ]}
                            itemRender={(route, params1, routes, paths) => {
                                return <div style={{ cursor: 'pointer' }} onClick={() => {
                                    if (route.path) {
                                        navigate('/club/admin/' + route.path + '?relkey=' + params.relkey + '&module=' + params.model);
                                    }
                                    return;
                                }}>{route.title}</div>
                            }}
                        />
                    </Col>
                    <Col span={8} style={{ textAlign: 'right' }}>
                        <Upload
                            name='file'
                            beforeUpload={() => false}
                            showUploadList={false}
                            multiple={false}
                            accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'
                            onChange={(item: any) => { onImportClick(item) }}
                        >
                            <Button icon={<UploadOutlined />} type='default'>导入</Button>
                        </Upload>&nbsp;&nbsp;
                        <Button icon={<PlusOutlined />} onClick={() => { onAddClick() }} type='primary'>添加</Button>
                    </Col>
                </Row>
            </Card>
            <Card bodyStyle={{ padding: 10 }} style={{ marginTop: 10, height: 'calc(100vh - 80px)' }}>
                <Table
                    rowKey={'key'}
                    bordered
                    size='small'
                    scroll={{ y: window.innerHeight - 190, x: '100%' }}
                    columns={[
                        {
                            title: '系统班级',
                            dataIndex: 'orgName',
                            key: 'orgName',
                        },
                        {
                            title: '自填班级',
                            dataIndex: 'orgSelf',
                            key: 'orgSelf',
                        },
                        {
                            title: '姓名',
                            dataIndex: 'name',
                            key: 'name',
                        },
                        {
                            title: '身份证号',
                            dataIndex: 'id',
                            key: 'id',
                            width: 160,
                        },
                        {
                            title: '操作',
                            key: 'action',
                            width: 100,
                            fixed: 'right',
                            render: (_, item) => (
                                <Row>
                                    <Col span={24}>
                                        <Button icon={<DeleteOutlined />} onClick={() => { onItemDeleteClick(item) }} type='link' danger>驳回</Button>
                                    </Col>
                                </Row>
                            ),
                        }]}
                    pagination={{
                        current: 1,
                        pageSize: 100,
                        pageSizeOptions: [100, 150, 200],
                        showTotal: (total, range) => {
                            return <>共{total}条数据，当前{range[0]}至{range[1]}条</>
                        },
                        onChange: (page, pageSize) => {
                            getData(page - 1, pageSize);
                        },
                    }}
                    dataSource={data} />
            </Card>
        </div>
        <Drawer
            title={dwr.title}
            placement="right"
            destroyOnClose
            onClose={() => { let newdrw = { ...dwr }; newdrw.visible = false; setDwr(newdrw); }}
            visible={dwr.visible}
            width={dwr.width}
            bodyStyle={{ backgroundColor: '#fff', padding: 0 }}
            className='dwr'
        >
            <Spin spinning={dwrLoading}>
                <div style={{ width: 'calc(100% - 40px)', height: '100%', padding: 16, overflow: 'hidden', overflowY: 'auto' }}>
                    {dwr.content}
                </div>
            </Spin>
        </Drawer>
    </Spin>
}