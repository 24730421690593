import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useLocation, useNavigate } from "react-router-dom";
import { Toast, Result, List, InfiniteScroll, Image, Button, Space, Grid } from 'antd-mobile';
import { StarOutline, EyeOutline, RightOutline } from 'antd-mobile-icons'
import moment from 'moment'
import { axiosPost } from '../../Utils/request';
let index = 0;

export const History: React.FC = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { search } = location
    const params = queryString.parse(search);
    const [data, setData] = useState([]);
    const [hasMore, setHasMore] = useState(true)
    const getData = () => {
        let body = {
            relkey: params.relkey,
            book: '',
            user: params.relkey,
            start: '',
            end:'',
            index: index,
            size: 20
        };
        Toast.show({ icon: 'loading', content: '加载中…', duration: 0, maskClickable: false });
        axiosPost(26, body, 'Book', (res: any) => {
            Toast.clear();
            if (res.code === 0) {
                setData(val => [...val, ...res.data.list]);
                setHasMore(true);
                return;
            }
            if (index === 0) {
                setData([]);
            }
            setHasMore(false);
            return;
        })
    }
    const onItemClick = (item: any) => {
        let body = {
            relkey: params.relkey,
            id: item.Book
        };
        Toast.show({ icon: 'loading', content: '查询中…', duration: 0, maskClickable: false });
        axiosPost(25, body, 'Book', (res: any) => {
            Toast.clear();
            let ext = item.AllUrl.split('.')[item.AllUrl.split('.').length - 1];
            if (ext === 'htm' || ext === 'html') {
                let urlStr = encodeURIComponent(item.AllUrl);
                navigate('/view?relkey=' + params.relkey + '&src=' + urlStr);
                return;
            }
            let viewUrl = 'http://117.187.12.43:21003/filePreview?type=' + ext + '&fileName=' + item.BookName + '&url=' + item.AllUrl;
            let urlStr = encodeURIComponent(viewUrl);
            navigate('/view?relkey=' + params.relkey + '&src=' + urlStr);
            return;
        })
    }
    const onAddShelfClick = (event: any, item: any) => {
        event.stopPropagation();
        let body = {
            relkey: params.relkey,
            book: item.Book,
            id: '',
        };
        Toast.show({ icon: 'loading', content: '提交中…', duration: 0, maskClickable: false });
        axiosPost(22, body, 'Book', (res: any) => {
            Toast.clear();
            if (res.code === 0) {
                Toast.show({ icon: 'success', content: '操作成功', maskClickable: false });
                return;
            }
            Toast.show({ icon: 'fail', content: res.message, maskClickable: false });
            return;
        })
    }
    useEffect(() => {
        index = 0;
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return data.length > 0
        ? <div style={{ textAlign: 'left', width: '100%', backgroundColor: '#fbfbfb' }}>
            <List
                className='bookList'
                style={{
                    '--border-inner': 'solid 0px var(--adm-border-color)',
                    '--border-top': 'solid 0px var(--adm-border-color)',
                    '--border-bottom': 'solid 0px var(--adm-border-color)',
                    backgroundColor: '#fbfbfb'
                }}
            >
                {data.map((item, index) => (
                    <List.Item
                        key={item.Id}
                        arrow={false}
                        clickable
                        onClick={() => { onItemClick(item) }}
                        style={{ marginTop: index === 0 ? 0 : 10 }}
                        description={<div>
                            <div style={{ marginTop: 5 }}>
                                <Grid columns={2}>
                                    <Grid.Item>
                                        类别：{item.SecTypeName}
                                    </Grid.Item>
                                    <Grid.Item>
                                        作者：{item.Author}
                                    </Grid.Item>
                                </Grid>
                            </div>
                            <div style={{ marginTop: 15 }}>
                                <Grid columns={2}>
                                    <Grid.Item style={{ textAlign: 'left', lineHeight: '30px' }}>
                                        <Grid columns={2}>
                                            <Grid.Item>
                                                <Space>
                                                    <EyeOutline />
                                                    <span> {item.ViewNum}</span>
                                                </Space>
                                            </Grid.Item>
                                            <Grid.Item>
                                                <Space>
                                                    <StarOutline />
                                                    <span> {item.FavTotal}</span>
                                                </Space>
                                            </Grid.Item>
                                        </Grid>
                                    </Grid.Item>
                                    {
                                        item.Shelf && item.Shelf !== null && item.Shelf !== ''
                                            ? null
                                            : <Grid.Item style={{ textAlign: 'right' }}>
                                                <Button color='primary' size='small' onClick={(event) => { onAddShelfClick(event, item) }} >
                                                    <Space>
                                                        <StarOutline />
                                                        <span>加入书架</span>
                                                    </Space>
                                                </Button>
                                            </Grid.Item>
                                    }
                                </Grid>
                            </div>
                            <div style={{ marginTop: 5 }}>
                                <Grid columns={1}>
                                    <Grid.Item style={{ textAlign: 'left', lineHeight: '30px' }}>
                                        {moment(item.Time).format("YYYY年MM月DD日 HH:mm:ss")}
                                    </Grid.Item>
                                </Grid>
                            </div>
                        </div>}
                        prefix={
                            <Image
                                src={item.BookImage}
                                style={{ borderRadius: 5, marginBottom: 10, marginTop: 10 }}
                                fit='cover'
                                width={80}
                                height={100}
                            />
                        }
                    >
                        <Grid columns={4}>
                            <Grid.Item span={3}>
                                {item.BookName.replace('\r', '').replace('\n', '')}
                            </Grid.Item>
                            <Grid.Item style={{ textAlign: 'right', color: '#999' }}>
                                <RightOutline />
                            </Grid.Item>
                        </Grid>
                    </List.Item>
                ))}
            </List>
            <InfiniteScroll loadMore={async (retry) => { index++; getData(); }} hasMore={hasMore} />
        </div >
        : <Result
            status='warning'
            title='提示'
            style={{ textAlign: 'center', width: '100%' }}
            description={<div>
                <div>未查询到数据</div>
            </div>
            }
        />
}