import React from 'react'
import { Modal, Input, Form, Button, Select, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
interface Props {
    title: string;
    fields?: Array<any>;
    open: boolean;
    values?: any;
    layout: any;
    width: number;
    onSave: (values: any) => void;
    onCancel: () => void;
}
const normFile = (e: any) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e?.fileList;
};
export const DrawForm: React.FC<Props> = (props) => {
    const [form] = Form.useForm();
    form.setFieldsValue(props.values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return <Modal
        open={props.open}
        title={props.title}
        okText="提交"
        cancelText="关闭"
        onCancel={props.onCancel}
        width={props.width}
        destroyOnClose
        onOk={() => {
            form.validateFields().then((values) => {
                if (props.values.id && props.values.id !== null && props.values.id !== '') {
                    values.id = props.values.id;
                }
                else {
                    values.id = '';
                }
                props.onSave(values);
                form.resetFields();
            }).catch((info) => {
            });
        }}
    >
        <Form.Provider>
            <Form form={form} layout={props.layout.layout} {...props.layout.itemLayout} style={{ width: '100%', marginTop: '20px' }} >
                {
                    props.fields.map(item => {
                        switch (item.type) {
                            case 'string':
                                return <Form.Item key={item.id} name={item.id} label={item.name} rules={[{ required: item.required }]} labelAlign={props.layout.itemAlign}>
                                    <Input />
                                </Form.Item>
                            case 'select':
                                return <Form.Item key={item.id} name={item.id} label={item.name} rules={[{ required: item.required }]} labelAlign={props.layout.itemAlign}>
                                    <Select>
                                        {
                                            item.options.map((option: any) => <Select.Option key={option.id} value={option.id}>{option.name}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            case 'file':
                                return <Form.Item
                                    key={item.id}
                                    name={item.id}
                                    label={item.name}
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                    rules={[{ required: item.required }]}
                                    labelAlign={props.layout.itemAlign}
                                >
                                    <Upload maxCount={item.count} beforeUpload={() => { return false }} multiple={item.multiple}>
                                        <Button icon={<UploadOutlined />}>上传</Button>
                                    </Upload>
                                </Form.Item>
                            case 'image':
                                return <Form.Item
                                    key={item.id}
                                    name={item.id}
                                    label={item.name}
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                    rules={[{ required: item.required }]}
                                    labelAlign={props.layout.itemAlign}
                                >
                                    <Upload maxCount={item.count} beforeUpload={() => { return false }} multiple={item.multiple} accept={'image/png,image/jpeg'}>
                                        <Button icon={<UploadOutlined />}>上传</Button>
                                    </Upload>
                                </Form.Item>
                            default:
                                return <Form.Item key={item.id} name={item.id} label={item.name} rules={[{ required: item.required }]} labelAlign={props.layout.itemAlign}>
                                    <Input />
                                </Form.Item>
                        }
                    })
                }
            </Form>
        </Form.Provider>
    </Modal>
}