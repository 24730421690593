import React from 'react'
import { NavBar, Form, Input, Button, Toast, Checkbox } from 'antd-mobile'
import { UserOutline, LockOutline } from 'antd-mobile-icons'
import { encryptByDES } from '../Utils/utils';
import { axiosPost } from '../Utils/request';
import { useNavigate } from 'react-router-dom';
export const Login: React.FC = () => {
    const navigate = useNavigate();
    const onFinish = (values: any) => {
        if (values.userId === undefined || values.userId === null || values.userId === '') {
            Toast.show('请输入用户名');
            return;
        }
        if (values.password === undefined || values.password === null || values.password === '') {
            Toast.show('请输入用户密码');
            return;
        }
        if (values.agreement === false || values.agreement === undefined) {
            Toast.show('请先同意用户协议');
            return;
        }
        let body = {
            username: values.userId,
            password: encryptByDES(values.password),
            type: 2
        };
        Toast.show({ icon: 'loading', content: '加载中…', duration: 0, maskClickable: false });
        axiosPost(0, body, 'Login', (res: any) => {
            if (res.code === 0) {
                Toast.clear();
                navigate('/ebook/home?relkey=' + res.data.userRels[0].RelKey);
                return;
            }
            body.type = 1;
            axiosPost(0, body, 'Login', (res: any) => {
                Toast.clear();
                if (res.code === 0) {
                    navigate('/ebook/home?relkey=' + res.data.userRels[0].RelKey);
                    return;
                }
                Toast.show({ icon: 'fail', content: '用户名或密码错误', maskClickable: false });
                return;
            })
        });
    }
    return <div style={{ borderBottom: 'solid 1px var(--adm-border-color)', backgroundColor: '#f0f0f0', height: window.innerHeight + 'px' }}>
        <NavBar back={null} style={{ backgroundColor: '#fff' }}>用户登录</NavBar>
        <div>
            <Form
                layout='horizontal'
                onFinish={onFinish}
                mode="card"
                style={{
                    '--border-inner': 'solid 0px var(--adm-border-color)',
                    '--border-top': 'solid 0px var(--adm-border-color)',
                    '--border-bottom': 'solid 0px var(--adm-border-color)',
                }}
                footer={
                    <Button block type='submit' color='primary' size='middle'>
                        登录
                    </Button>
                }>
                <Form.Item label={<div style={{ fontWeight: 'bold' }}><UserOutline />&nbsp;&nbsp;账&nbsp;&nbsp;号：</div>} name='userId' rules={[{ required: false, message: '用户名不能为空' }]}>
                    <Input placeholder='请输入合云校账号' clearable />
                </Form.Item>
                <Form.Item style={{ marginTop: '10px' }} label={<div style={{ fontWeight: 'bold' }}><LockOutline />&nbsp;&nbsp;密&nbsp;&nbsp;码：</div>} name='password' rules={[{ required: false, message: '密码不能为空' }]}>
                    <Input placeholder='请输入账号密码' clearable type='password' />
                </Form.Item>
                <Form.Item style={{ marginTop: '10px' }} name='agreement' label={null} rules={[{ required: false, message: '请勾选用户协议' }]}>
                    <Checkbox style={{
                        '--icon-size': '18px',
                        '--font-size': '14px',
                        '--gap': '6px',
                    }}>
                        <div style={{fontSize:'12px'}}>
                            我已阅读且同意
                            <Button
                                fill='none'
                                color='primary'
                                size='mini'
                                style={{ fontSize: '12px', padding: 0 }}
                                onClick={() => {
                                    navigate('/agreement')
                                }}
                            >
                                《合·云校用户许可及应用服务协议》
                            </Button>
                        </div>
                    </Checkbox>
                </Form.Item>
            </Form>
        </div>
    </div>
}